import React, { useState, useEffect, useRef } from "react";
import { Button, Popconfirm, Space, Table, Input, Spin, message, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { CopyOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import Highlighter from "react-highlight-words";
import SearchComponent from "../SearchComponent";


export default function Update() {


    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [selected, setSelected] = useState({
        dateRange: [moment(), moment()],
    });
    const [info, setInfo] = useState([]);
    const [actionPlans, setActionPlans] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [masterState, setMasterState] = useState([]);
    const navigate = useNavigate();


    function request() {
        setLoading(true);
        console.log("Set Loading to True../")
        axios.get("/generic-api/mobile/list")
            .then((res) => {
                setInfo(res?.data?.data);
                const data = res.data.data
                // navigate("/home/update");
                console.log("info", info)
                setLoading(false);
                setMasterState(data)
                setActionPlans(data)
            })
            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
    }

    const handleDelete = (record) => {
        console.log(record);
        console.log("Delete Called");
        axios
            .delete(`/generic-api/mobile/delete?m_id=${record.m_id} `)
            .then((res) => {
                message.success("App Update Deleted Successfully");
                request();
            })
            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
    };

    const columns = [
        {
            title: "Organisation Name",
            dataIndex: "o_name",
            key: "o_name",
            // ...getColumnSearchProps("version_name"),
        },
        {
            title: "Version Name",
            dataIndex: "version_name",
            key: "version_name",
            // ...getColumnSearchProps("version_name"),
        },
        {
            title: "Release Date",
            dataIndex: "release_date",
            render: (text, record) => moment(text).format("DD MMM YYYY hh:mm:ss a"),
        },
        {
            title: "Build Date",
            dataIndex: "build_date",
            render: (text, record) => moment(text).format("DD MMM YYYY hh:mm:ss a"),
        },
        {
            title: "Url",
            dataIndex: "url",
            key: "url",
            width:"50%",
            // ...getColumnSearchProps("url"),
        },
        {
            title: "File Name",
            dataIndex: "file_name",
            key: "file_name",
            // ...getColumnSearchProps("file_name"),
        },
        {
            title: "App Name",
            dataIndex: "application_name",
            key: "application_name",
            // ...getColumnSearchProps("app_id"),
        },
        {
            title: "Production",
            render: (text, record) => (record.is_prod ? "true" : "false"),
            // ...getColumnSearchProps("is_prod"),
        },
        {
            title: "Enable",
            // dataIndex: "is_enable",
            // added boolean value to show enable or disable
            render: (text, record) => (record.is_enable ? "true" : "false"),
            // ...getColumnSearchProps("is_prod"),
        },
        {
            title: "Logout",
            // dataIndex: "is_auto_logout",
            // added boolean value to show enable or disable
            render: (text, record) => (record.is_auto_logout ? "true" : "false"),
            // ...getColumnSearchProps("is_prod"),
        },
        {
            title: "Update",
            // dataIndex: "is_force_update",
            // added boolean value to show enable or disable
            render: (text, record) => (record.is_force_update ? "true" : "false"),
            // ...getColumnSearchProps("is_prod"),
        },
        {
            title: "Operation",
            dataIndex: "operation",
            minWidth:"20%",
            render: (_, record) =>
                info.length >= 1 ? (
                    <>
                        <Space align="center">

                            <Button
                                icon={<EditOutlined />}
                                onClick={() => { navigate(`/home/update_edit/${record.m_id}`); }}
                                className="bg-sky-600"
                                type="primary">Edit</Button>
                            <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => handleDelete(record)}>
                                <Button type="primary" danger>Delete</Button>
                            </Popconfirm>
                        </Space>
                        <div style={{ justifyItems: "center" }}>
                            <Button
                                icon={<CopyOutlined />}
                                onClick={() => { navigate(`/home/update_add`, { state: { record }, }); }}
                                type="primary"
                                style={{ background: "orange", borderColor: "orange", marginTop: "10px" }}>
                                Copy Details
                            </Button>
                        </div>
                    </>
                ) : null,
        },
    ];

    useEffect(() => {
        request();
    }, []);


    return (
        <>
            <header className="flex justify-between items-center" style={{padding:'5px'}}>
                <p className="my-form-title">App Update</p>
                <Button
                    onClick={() => navigate("/home/update_add")}
                    type="primary"
                    className="bg-sky-600"
                    style={{ margin: 0, float: "right" }}>Add</Button>
            </header>
            <Divider />
            <div className="flex justify-between items-center h-16">
                <SearchComponent
                    masterState={masterState}
                    state={actionPlans}
                    setState={setActionPlans}
                    searchOptions={[
                        { keyName: "o_name", label: "Organisation Name" },
                        { keyName: "version_name", label: "Version Name" },
                        { keyName: "url", label: "URL" },
                        { keyName: "file_name", label: "File Name" },
                        { keyName: "application_name", label: "Application Name" },
                        // {keyName: 'zone_groups', label: 'Zone Groups'},
                    ]}
                />
            </div>

            <div className="my-form-outer">
                {/* <div
                    style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "0px",
                        fontSize: "small",
                    }}
                >
                    <div></div>
                    <SearchComponent
                        masterState={masterState}
                        state={actionPlans}
                        setState={setActionPlans}
                        searchOptions={[
                            { keyName: "o_name", label: "Organisation Name" },
                            { keyName: "version_name", label: "Version Name" },
                            { keyName: "url", label: "URL" },
                            { keyName: "file_name", label: "File Name" },
                            { keyName: "application_name", label: "Application Name" },
                            // {keyName: 'zone_groups', label: 'Zone Groups'},
                        ]}
                    />
                </div> */}
                <div style={{ position: "relative", textAlign: "right" }}>
                    {loading ? (
                        <Spin
                            size="large"
                            style={{ display: "flex", alignItems: "center" }}
                            tip="Loading"
                        />
                    ) : (
                        <Table
                            columns={columns}
                            loading={loading}
                            dataSource={actionPlans}
                            size="small"
                            pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
