import React from "react";
import {
    Button,
    Form,
    Input,
    InputNumber,
    DatePicker,
    Space,
    Tabs,
    Select,
    Row,
    Col,
    message,
    Switch,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo } from "react";
import {
    useNavigate,
    createSearchParams,
    useSearchParams,
    useParams,
    useLocation,
} from "react-router-dom";
import axios from "axios";
import TabPane from "antd/es/tabs/TabPane";
import { InputWithLabel } from "../InputWithLabel";
import { validateUser } from "../../Validation/Validations";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const { RangePicker } = DatePicker;
const validateMessages = {
    required: "${label} is required!",
};

/* eslint-enable no-template-curly-in-string */

const onFinish = (values) => {
    console.log("Received values of form:", values);
};

export default function EditUser({ isAddNew }) {
    const user_id = useParams();
    console.log(user_id)
    const location = useLocation();
    const [users, setUser] = useState([]);
    const [application, setApplication] = useState({
        username: "",
        password: "",
        is_admin: null
    });
    const [aid, setAid] = useState([]);
    const [details2, setDetails2] = useState([]);
    const [orgid, setorgid] = useState(" ");
    const [orgname, setorgname] = useState(" ");
    const [orgurl, setorgurl] = useState(" ");
    const [orgcode, setorgcode] = useState(" ");
    const [licenselimit, setLicenseLimit] = useState(100);
    const [applicationID, setApplicationID] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [errors, setErrors] = useState({});
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'details');
    const [loading, setLoading] = React.useState(false);
    const [applicationtype, setApplicationtype] = useState([]);
 
    const navigate = useNavigate();
    const setDetailsKey = (key, value) => {
        setApplication((d) => ({
            ...d,
            [key]: value,
        }));
    };

    const getDetails = async () => {
        axios
            .get(`/generic-api/get_users_by_userid?user_id=${user_id.user_id}`)
            .then((res) => {
                const data = res?.data?.data[0]
                console.log(data)
                setApplication((d) => ({
                    ...d,
                    ...data,
                    username: data.username,
                    password: data.password,
                    is_admin: data.is_admin

                }));

                // console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
    };

    useEffect(() => {
        getDetails();
    }, []);

    const saveButton = (
        <Space>
            <Button
                type="primary"
                danger
                onClick={() => navigate("/home/users")}
            >
                Cancel
            </Button>

            <Button type="primary" className="bg-sky-600" htmlType="submit" onClick={onSubmit} >
                {isAddNew ? "Add" : "Save"}
            </Button>
        </Space>
    );
    function onSubmit(values) {
        console.log("add api called", values);
        let d, e, errorTab, errStatus, errorsIn;
        ({ d, e, errorTab, errStatus, errorsIn } = validateUser(
          application,
          errors,
          isAddNew,
          "details"
        ));
        setApplication((prev) => ({ ...prev, ...d }));
        setErrors((prev) => ({ ...prev, ...e }));
    
        if (errStatus === "error") {
          console.log("Error in form : ", errorsIn);
          setActiveTab(errorTab);
          console.log(errors);
          setLoading(false);
          return;
        }
        if (isAddNew) {
            axios
            .post("/generic-api/create_user", {
                "username": application.username,
                "password": application.password,
                "is_admin": application.is_admin ? true : false
            })
            .then((res) => {
                message.success("User added successfully");
                navigate("/home/users")
            })
            .catch((err) => {
                // message.error(err);
                message.error("Error : " + err.response.data.detail);
            });
        console.log(values);
        {
            console.log(isAddNew);
        }
        
        }
        if (!isAddNew) {
            axios
            .put(`/generic-api/modify_user?user_id=${user_id.user_id}`, {
                "username": application.username,
                "password": application.password,
                "is_admin": application.is_admin
            })
            .then((res) => {
                message.success("User Edited successfully");
                navigate("/home/users")
            })
            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
        console.log(values);
        {
            console.log(isAddNew);
        }
          
      }
    }
    return (
        <>
         
                <Tabs className="card-tabs" tabBarExtraContent={saveButton} activeKey={activeTab}>
                <TabPane tab="User Details" key="details">
                <div className="my-form-outer">
                    <div className="my-form-multiple-inline-input">
                        <InputWithLabel label="Username" reqMark={true} error={errors.username}>
                            <Input value={application.username}
                                          status={errors?.username?.errors[0]?.msg && 'error'}

                                required={true}
                                className="my-form-input"
                                onChange={(e) => setDetailsKey("username", e.target.value)} />
                        </InputWithLabel>
                        <InputWithLabel label="Password" reqMark={true} error={errors.password}>
                            <Input value={application.password}
                                          status={errors?.password?.errors[0]?.msg && 'error'}

                                required={true}
                                className="my-form-input"
                                onChange={(e) => setDetailsKey("password", e.target.value)}
                            //    disabled={isAddNew === false ? true:false}
                            />
                        </InputWithLabel>
                        <InputWithLabel label="Admin" valuePropName="checked">
                            <Switch
                                
                                className="bg-sky-600"
                                checked={application.is_admin ? true : false}
                                onChange={(e) => { setDetailsKey('is_admin', e) }}
                                checkedChildren="Yes"
                                unCheckedChildren=" No "
                            />
                        </InputWithLabel>
                    </div>
                </div>
                </TabPane>
                </Tabs>
        </>
    );
}
