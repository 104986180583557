import React from 'react';
import { Button, Form, Input, InputNumber, DatePicker,Dropdown, Select, Cascader, Space, message} from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate, createSearchParams, useSearchParams, useLocation } from 'react-router-dom';
import axios from "axios";
import dayjs from 'dayjs';
import moment from 'moment';
import { InputWithLabel } from '../InputWithLabel';
const dateFormat = 'YYYY/MM/DD';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: '${label} is required!',
};
export default function EditRegister({
}) {
  const [details, setDetails] = useState([]);
  const [details2, setDetails2] = useState([]);
  const onFinish = async(values) => {
    
    
    console.log("add api called", values)
  };
  const getDetails = async () => {
    await axios.get('generic-api/get_application_types')
        .then(res => {
            setDetails(res.data)
            console.log(res.data)
        }).catch(err => {
            console.log(err);
            message.error("Error : " + err);

          
        })
        await axios.get('generic-api/get_organisations')
        .then(res => {
            setDetails2(res.data.data)

        }).catch(err => {
            console.log(err);

          
        })
}
const orgsubmitHandler = async(e) => {
  e.preventDefault();
  
}
useEffect(() => {
      getDetails();
  
}, []);

  const location = useLocation();
  const navigate = useNavigate();
  let registerName = location.state.record.register_user_name
  let initialLicenseValidity = location.state.record.license_validity.split("T")
  let date = initialLicenseValidity[0]
  let registerCode = location.state.record.register_code
  let initialAppName = location.state.record.application_name
  let initialOrgName = location.state.record.o_name

  
  return (
    
    <Form 
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      labelAlign='left'>
<div className="my-form-outer">
              <div >

      <Form.Item
        name="RegisterName"
        label="Register Name"
        initialValue={registerName}
        style={{ paddingTop: "5px", width: "30%" }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input/>
      </Form.Item>

        <Form.Item 
        
        label="Register Code" 
      rules={[
        {
          required: true,
        },
        
      ]}>
          <InputWithLabel label={registerCode}/>
        </Form.Item>
        <Form.Item name="OrganisationName" initialValue={initialOrgName}
        label="Organisation Name"
        // onChange={orgurlchangeHandler}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
                    options={details2?.map((items) => ({
                      value: items.o_id,
                      label: items.o_name,
                    }))}
                />
      </Form.Item>

      <Form.Item name="ApplicationName" initialValue={initialAppName}
        label="Application Name" 
        // onChange={orgurlchangeHandler}
        rules={[
          {
            required: true,
          },
        ]}
      >
        
       <Select
                    options={details?.map((item) => ({
                      value: item.application_id,
                      label: item.application_name,
                    }))}
                >
        </Select>
      </Form.Item>
      
      <Form.Item 
      name="LicenseValidity"
      label="License Validity" 
      rules={[
        {
          required: true,
        },
        
      ]}>
          <DatePicker format={dateFormat} defaultValue= {dayjs(date, dateFormat)}/>
          
          {/* value={initialLicenseValidity} format="Do MMM, YYYY"  */}
        </Form.Item >
      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8,
        }}
      >
      <Space>
        <Button type='primary' danger onClick={() => navigate("/home/registers")}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" >
        Save
        </Button>
      </Space>
      </Form.Item>
      </div>
    </div>
    </Form>
    
  );
};
// export default EditLicense;