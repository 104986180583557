import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import EditOrg from "./Components/Organisations/EditOrg";
import Login from "./Components/Login";
import Organisations from "./Components/Organisations/Organisations";
import Application from "./Components/Application/Application";
import License from "./Components/License/License";
import EditLicense from "./Components/License/EditLicense";
import Registers from "./Components/Registers/Registers";
import EditRegister from "./Components/Registers/EditRegister";
import EditApp from "./Components/Application/EditApp";
import RegisterView from "./Components/Registers/RegisterView";

import Update from "./Components/MobileUpdate/Update";
import EditUpdate from "./Components/MobileUpdate/EditUpdate";
import AddUpdate from "./Components/MobileUpdate/AddUpdate";
import Users from "./Components/UsersMenu/Users";
import EditUser from "./Components/UsersMenu/EditUser";
import AppLayout from "./AppLayout/AppLayout";
import {
  DesktopOutlined,
  CloudServerOutlined,
  LogoutOutlined,
  UserOutlined,
  IdcardOutlined,
  PicCenterOutlined,
  AppstoreOutlined,
  BuildOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { useMemo } from "react";
import { message } from "antd";
import Dashboard from "./Components/Dashboard/Dashboard";
import TotalActive from "./Components/Dashboard/TotalActive";
import ExpiryInSeven from "./Components/Dashboard/ExpiryInSeven";
import TotalUnused from "./Components/Dashboard/Total_Unused";
import UnusedRegisters from "./Components/Dashboard/UnusedRegister";

function App() {
  const navigate = useNavigate();
  const menuItems = useMemo(() => [
    {
      key: "Dashboard",
      label: "Dashboard",
      icon: <DashboardOutlined/>,
      search: "Dashboard",
      onClick: () => {
        navigate("/home");
      },
    },
    {
      key: "Organisations",
      label: "Organisations",
      icon: <DesktopOutlined />,
      search: "Organisations",
      onClick: () => {
        navigate("/home/organisations");
      },
    },
    {
      key: "License",
      label: "License",
      icon: <IdcardOutlined />,
      search: "License",
      onClick: () => {
        navigate("/home/license");
      },
    },
    {
      key: "Registers",
      label: "Registers",
      icon: <PicCenterOutlined />,
      search: "Registers",
      onClick: () => {
        navigate("/home/registers");
      },
    },
    {
      key: "Application",
      label: "Application",
      icon: <AppstoreOutlined />,
      search: "Application",
      onClick: () => {
        navigate("/home/application");
      },
    },
    {
      key: "App Update",
      label: "App Update",
      icon: <BuildOutlined />,
      search: "App Update",
      onClick: () => {
        navigate("/home/update");
      },
    },
    {
      key: "Users",
      label: "Users",
      icon: <UserOutlined />,
      search: "Users",
      onClick: () => {
        navigate("/home/users");
      },
    },
    {
      label: "Logout",
      key: "logout",
      name: "Logout",
      danger: true,
      search: "logout",
      icon: <LogoutOutlined />,
      // style: { marginTop: "530px" },
      onClick: () => {
        if (localStorage.getItem("AdminToken") === "true") {
          window.location.href = "/";
          localStorage.removeItem("AdminValidate");
        } else {
          message.error("Cannot Logout without an admin");
        }
      },
    },
  ]);
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="home"
          element={
            localStorage.getItem("AdminValidate") ? (
              <AppLayout
                menuItems={
                  localStorage.getItem("AdminValidate") === "true"
                    ? menuItems
                    : menuItems.filter((item) => item.key !== "Users")
                }
              />
            ) : (
              <Navigate replace to={"/"} />
            )
          }
        >

          <Route path="" element ={<Dashboard/>}>
            </Route>
          <Route
            path="totalactivelicenses"
            element={<TotalActive/>}
          />
          <Route
            path="expiry_in_seven_days"
            element={<ExpiryInSeven/>}
          />
          <Route
            path="total_unused_licenses"
            element={<TotalUnused/>}
          />
          <Route
            path="unused_registers"
            element={<UnusedRegisters/>}
          />
          <Route path="organisations" element={<Organisations />}></Route>
          <Route
            path="organisations/edit/:o_id"
            element={<EditOrg isAddNew={false} />}
          />
          <Route
            path="organisations/add"
            element={<EditOrg isAddNew={true} />}
          />
          <Route
            path="edit_license/:license_id"
            element={<EditLicense isAddNew={false} />}
          />
          <Route path="edit_register/:register_id" element={<EditRegister />} />
          <Route path="add_license" element={<EditLicense isAddNew={true} />} />
          <Route path="license" element={<License />} />
          <Route path="registers" element={<Registers />} />
          <Route path="register_view/:reg_id" element={<RegisterView />} />
          <Route path="update" element={<Update />} />
          <Route path="users" element={<Users />} />
          <Route
            path="users/edit/:user_id"
            element={<EditUser isAddNew={false} />}
          />
          <Route path="users/add" element={<EditUser isAddNew={true} />} />

          <Route path="update_add" element={<AddUpdate isAddNew={true} />} />
          <Route path="update_edit/:m_id" element={<AddUpdate isAddNew={false}/>} />
          {/* <Route path="update_add" element={<EditUpdate isAddNew={true} />} /> */}
          {/* <Route path="update_edit/:m_id" element={<EditUpdate isAddNew={false} />} /> */}
          <Route path="organisations/add" element={<EditOrg />} />
          <Route path="application" element={<Application />} />
          <Route path="application/add" element={<EditApp isAddNew={true} />} />
          <Route
            path="application/edit/:application_id"
            element={<EditApp isAddNew={false} />}
          />
        </Route>

        <Route path="/" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
