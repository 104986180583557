import React, { useEffect, useState } from "react";
import { Table, Button, Space, Popconfirm, message, Spin, Divider, Result, Badge, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from 'antd';

import axios from "axios";
import SearchComponent from "../SearchComponent";

const Dashboard = () => {

    const [orgInfo, setOrgInfo] = useState([]);
    const [displayCount, setDisplayCount] = useState([]);
    const [actionPlans, setActionPlans] = React.useState([]);
    const [masterState, setMasterState] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    function request() {
        setLoading(true);
        axios
            .get("/dashboard/org_wise_details")
            .then((res) => {
                
                const data = res.data.data;
                const convertedArray = data.flat();

console.log(convertedArray);
setOrgInfo(convertedArray);
                console.log(data);
                setActionPlans(data);
                setMasterState(data);
                setLoading(false);
                // console.log(users)
            })
            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
            axios
            .get("/dashboard/display_count")
            .then((res) => {
                setDisplayCount(res.data.data)
                const data = res.data.data;
                console.log(data);
                setLoading(false);
                // console.log(users)
            })
            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
    }

    useEffect(() => {
        request();
    }, []);

    const handleDelete = (record) => {
        console.log(record.application_id);
        axios
            .delete(
                `/generic-api/delete_application_types?application_id=${record.application_id} `
            )
            .then((res) => {
                if (res?.data?.data?.status_code == 200) {
                    message.success(record.o_id + "deleted successfully") &&
                        request();
                } else {
                    message.error("OPPs something went wrong!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const Columns = [
        {
            title: "Org Name",
            dataIndex: "org_name",
            key: "org_name",
            render:(text,record)=><b>{record.org_name}</b>
        },
        {
            title: "Total Licenses",
            dataIndex: "total_licenses",
            key: "total_licenses",
            width:"15%"
            // render:(text,record)=><div><Tag color="#108ee9">{record.total_licenses}</Tag></div>
        },
        {
            title: "Total Devices",
            dataIndex: "total_devices",
            key: "total_devices",
            width:"15%"
            // render:(text,record)=><div><Tag color="#87d068">{record.total_devices}</Tag></div>
            

        },
        {
            title: "Unused Licenses",
            dataIndex: "total_unused",
            key: "total_unused",
            width:"15%"
        },
        {
            title: "Expiry in 7 Days",
            dataIndex: "expiring_in_seven_days",
            key: "expiring_in_seven_days",
            width:"15%"
        },
        {
            title: "Expired",
            dataIndex: "expired",
            key: "expired",
            width:"15%"
        },
        
    ];

    return (
        <>
            <header className="flex justify-between items-center" style={{padding:"5px"}}>
                <p className="my-form-title">Dashboard</p>
            </header>
            <Divider />
            <div className="flex justify-between items-center">
                {/* <SearchComponent
                    masterState={masterState}
                    state={actionPlans}
                    setState={setActionPlans}
                    searchOptions={[
                        { keyName: "application_name", label: "Application Name" },
                        { keyName: "application_code", label: "Application Code" },
                    ]}
                /> */}
            </div>
            <div className="my-form-outer">
                {loading ? (
                    <Spin
                        size="large"
                        style={{ display: "flex", alignItems: "center" }}
                        tip="Loading"
                    />
                ) : (
                    <><Row style={{margin:"15px 0px 15px",display:"flex", flexWrap:"wrap",justifyContent:"space-evenly"}}>
                    <Col style={{margin:"15px 0px 15px",display:"flex",justifyContent:"space-evenly"}}>
                      <Card style={{ borderRadius:"10px", boxShadow:"6px 6px 10px #D3D3D3", minWidth:"300px", display:"flex", justifyContent:"center", alignItems:"center"}} bordered={true} hoverable>
                        
                        <span style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"1.2rem", fontWeight:"500"}}>
                        <a
                                    href="home/totalactivelicenses"
                                    onClick={() =>
                                        (window.location.href = "/new-page")
                                    }
                                >
                                Total Active Licenses &nbsp; <b>Upto 7 Days</b></a>
                            </span>
                        
                        <b style={{ color:"green", fontSize:"30px", display:"flex", justifyContent:"center", alignItems:"center"}}>{displayCount.total_active}</b>
                        


                      </Card>
                    </Col>
                    <Col style={{margin:"15px 0px 15px",display:"flex",justifyContent:"space-evenly"}}>
                      <Card  bordered={true} hoverable style={{ borderRadius:"10px", boxShadow:"6px 6px 10px #D3D3D3", minWidth:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        
                      {/* <b ><Badge style={{display:"flex", justifyContent:"center", alignItems:"center"}} status="warning" text="Expiry in 7 Days"/></b> */}
                      <span style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"1.2rem", fontWeight:"500"}}>
                      <a
                                    href="home/expiry_in_seven_days"
                                    onClick={() =>
                                        (window.location.href = "/new-page")
                                    }
                                >
                        Expiry in 7 Days </a></span>
                      <b style={{color:"red", fontSize:"30px", display:"flex", justifyContent:"center", alignItems:"center"}}>{displayCount.expiry_in_seven_days}</b>

                        
                      </Card>
                    </Col>
                    <Col style={{margin:"15px 0px 15px",display:"flex",justifyContent:"space-evenly"}}>
                      <Card bordered={true} hoverable style={{ borderRadius:"10px", boxShadow:"6px 6px 10px #D3D3D3", minWidth:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        
                        {/* <b ><Badge style={{display:"flex", justifyContent:"center", alignItems:"center"}} status="error" text="Total Unused Licenses"/></b> */}
                        <span style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"1.2rem", fontWeight:"500"}}>
                        <a
                                    href="home/total_unused_licenses"
                                    onClick={() =>
                                        (window.location.href = "/new-page")
                                    }
                                >
                            Total Unused Licenses</a></span>
                        <b style={{color:"coral", fontSize:"30px", display:"flex", justifyContent:"center", alignItems:"center"}}>{displayCount.unused_licenses}</b>
                        
                      </Card>
                    </Col>
                    <Col style={{margin:"15px 0px 15px",display:"flex",justifyContent:"space-evenly"}} >
                      <Card bordered={true} hoverable style={{borderRadius:"10px", boxShadow:"6px 6px 10px #D3D3D3", minWidth:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        
                        {/* <b ><Badge style={{display:"flex", justifyContent:"center", alignItems:"center"}} status="error" text="Total Unused Registers"/></b> */}
                        <span style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"1.2rem", fontWeight:"500"}}>
                        <a
                                    href="home/unused_registers"
                                    onClick={() =>
                                        (window.location.href = "/new-page")
                                    }
                                >
                            Total Unused Registers</a>
                            </span>
                        <b style={{color:"brown", fontSize:"30px", display:"flex", justifyContent:"center", alignItems:"center"}}>{displayCount.unused_register}</b>
                        
                      </Card>
                    </Col>
                  </Row>
                    <Table
                        bordered
                        dataSource={orgInfo}
                        columns={Columns}
                        loading={loading}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            
                        }}
                    />
                    </>
                )}
            </div>
        </>
    );
};
export default Dashboard;
