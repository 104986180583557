import React from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Dropdown,
  Select,
  Cascader,
  Space,
  Tabs,
  message,
} from "antd";
import { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  createSearchParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { InputWithLabel } from "../InputWithLabel";
import "../MobileUpdate/EditUpdate.css";
import TextArea from "antd/lib/input/TextArea";
dayjs.extend(weekday);
dayjs.extend(localeData);
const dateFormat = "YYYY/MM/DD";
const validateMessages = {
  required: "${label} is required!",
};

const EditUpdate = () => {
  const [update, setUpdate] = useState({
    m_id: null,
    version_name: "",
    version_code: null,
    release_date: "",
    build_date: "",
    change_log: "",
    url: "",
    file_name: "",
    app_type: null,
    is_prod: null,
    created: "",
    updated: "",
    git_hash_num: "",
    app_id: null,
    is_enable: null,
    is_auto_logout : null,
    is_force_update: null,
    o_id: null,
    o_name: "",
  });
  const m_id = useParams();
  // console.log(m_id)

  const [details, setDetails] = useState([]);
  const [details2, setDetails2] = useState([]);

  const truefalseselect = [
    { key: true, value: "True" },
    { key: false, value: "False" },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  function onEditFinish(values) {
    if (update.o_id&&update.app_id) {
    axios
      .put(`/generic-api/mobile/modify?m_id=${update.m_id}`, {
        ...update,
        // m_id: location.state.record.m_id,
      })
      .then((res) => {
        console.log(res.data);
        message.success("App Update Modified Successfully");
        navigate("/home/update");
      })
      .catch((err) => {
        console.log(err);
        message.error("Error : " + err);

      });
    }else{
      message.error("Please Fill All The Fields")
  }
    console.log("edit is calllled", values);
  }
  const setDetailsKey = (key, value) => {
    setUpdate((d) => ({
      ...d,
      [key]: value,
    }));
  };
  const getDetails = async () => {
    await axios
      .get("/generic-api/get-mobile-by-mobile-id", {
        params: {
          m_id: m_id.m_id,
        },
      })
      .then((res) => {
        const data = res.data.data[0];
        // console.log(data)
        setUpdate((d) => ({
          ...d,
          ...data,

          m_id: data.m_id,
          version_name: data.version_name || "",
          version_code: data.version_code,
          release_date: data.release_date,
          build_date: data.build_date,
          change_log: data.change_log,
          url: data.url,
          file_name: data.file_name,
          app_type: data.app_type,
          is_prod: data.is_prod,
          created: data.created,
          updated: data.updated,
          git_hash_num: data.git_hash_num,
          app_id: data.app_id,
          is_enable: data.is_enable,
          is_auto_logout:data.is_auto_logout,
          is_force_update :data.is_force_update,
          o_id: data.o_id,
          o_name: data.o_name,
        }));
        console.log(update);
      })

      .catch((err) => {
        console.log(err);
      });
    await axios
      .get("generic-api/get_organisations")
      .then((res) => {
        setDetails2(res?.data?.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error : " + err);
      });
    //    console.log("location state data",location.state.record);

    await axios
      .get("generic-api/get_application_types")
      .then((res) => {
        setDetails(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error : " + err);
      });
    //  console.log("location state data",location.state.record);
  };

  useEffect(() => {
    getDetails();
  }, []);

  const saveButton = (
    <Space>
      <Button type="primary" danger onClick={() => navigate("/home/update")}>
        Cancel
      </Button>

      <Button type="primary" className="bg-sky-600" htmlType="submit">
        save
      </Button>
    </Space>
  );

  return (
    <div className="my-form-outer">
      <Form
        name="nest-messages"
        // initialValues={location?.state?.record}
        onFinish={onEditFinish}
        validateMessages={validateMessages}
        labelAlign="left"
      >
        <Tabs tabBarExtraContent={saveButton} />
        {/* <div className="formitem"> */}
        <div className="my-form-multiple-inline-input">
        <InputWithLabel label="Organisation Name" reqMark={true}>
          <Select
            className="my-form-input"
            value={update.o_id}
            onChange={(value) => setDetailsKey("o_id", value)}
            // placeholder="Select Organisation Name"
            // name="OrganisationName"
            options={details2?.map((items) => ({
              value: items.o_id,
              label: items.o_name,
            }))}
          />
        </InputWithLabel>
        
          <InputWithLabel label="Version name" reqMark={true}>
            <Input
            required={true}
              value={update.version_name}
              className="my-form-input"
              onChange={(e) => setDetailsKey("version_name", e.target.value)}
            />
          </InputWithLabel>
          <InputWithLabel label="Version code" reqMark={true}>
            <Input
            required={true}
              value={update.version_code}
              className="my-form-input"
              onChange={(e) => setDetailsKey("version_code", e.target.value)}
              placeholder="Enter Only Number"
            />
          </InputWithLabel>
        </div>
        <div className="my-form-multiple-inline-input">
          <InputWithLabel label="Release date" reqMark={true}>
            <DatePicker
              allowClear={false}
              value={
                update.release_date !== ""
                  ? dayjs(update.release_date, dateFormat)
                  : null
              }
              onChange={(date, dateString) => {
                setDetailsKey("release_date", date);
              }}
              className="my-form-input"
              format={dateFormat}
            />
          </InputWithLabel>
          <InputWithLabel label="Build date" reqMark={true}>
            <DatePicker
              allowClear={false}
              value={
                update.build_date !== ""
                  ? dayjs(update.build_date, dateFormat)
                  : null
              }
              onChange={(date, dateString) => {
                setDetailsKey("build_date", date);
              }}
              className="my-form-input"
              format={dateFormat}
            />
          </InputWithLabel>
          <InputWithLabel label="Git Hash Number" reqMark={true}>
            <Input 
            required={true}
              value={update.git_hash_num}
              onChange={(e) => setDetailsKey("git_hash_num", e.target.value)}
              className="my-form-input"
            />
          </InputWithLabel>
         
        </div>
        <div className="my-form-multiple-inline-input">
         
          <InputWithLabel label="Url" reqMark={true}>
            <Input
            required={true}
              className="my-form-input"
              value={update.url}
              onChange={(e) => setDetailsKey("url", e.target.value)}
            />
          </InputWithLabel>
          <InputWithLabel label="File name" reqMark={true}>
            <Input
            required={true}
              className="my-form-input"
              value={update.file_name}
              onChange={(e) => setDetailsKey("file_name", e.target.value)}
            />
          </InputWithLabel>
          <InputWithLabel label="Application Name" reqMark={true}>
            <Select
              className="my-form-input"
              name="ApplicationName"
              placeholder="Select Application Name"
              value={update.app_id}
              onChange={(value) => setDetailsKey("app_id", value)}
              options={details?.map((item) => ({
                value: item.application_id,
                label: item.application_name,
              }))}
            ></Select>
          </InputWithLabel>
        </div>
        <div className="my-form-multiple-inline-input">
         
          <InputWithLabel label="Production" reqMark={true}>
            <Select
              className="my-form-input"
              value={update.is_prod}
              onChange={(value) => setDetailsKey("is_prod", value)}
              options={truefalseselect?.map((item) => ({
                value: item.key,
                label: item.value,
              }))}
            ></Select>
          </InputWithLabel>
          <InputWithLabel label="Enable" reqMark={true}>
            <Select
              className="my-form-input"
              name="Enable"
              value={update.is_enable}
              onChange={(value) => setDetailsKey("is_enable", value)}
              options={truefalseselect?.map((item) => ({
                value: item.key,
                label: item.value,
              }))}
            ></Select>
          </InputWithLabel>
          <InputWithLabel label="Enable" reqMark={true}>
            <Select
              className="my-form-input"
              name="Enable"
              value={update.is_auto_logout}
              onChange={(value) => setDetailsKey("is_auto_logout", value)}
              options={truefalseselect?.map((item) => ({
                value: item.key,
                label: item.value,
              }))}
            ></Select>
          </InputWithLabel>
          <InputWithLabel label="Enable" reqMark={true}>
            <Select
              className="my-form-input"
              name="Enable"
              value={update.is_force_update}
              onChange={(value) => setDetailsKey("is_force_update", value)}
              options={truefalseselect?.map((item) => ({
                value: item.key,
                label: item.value,
              }))}
            ></Select>
          </InputWithLabel>
        </div>
        <div className="my-form-multiple-inline-input">
        <InputWithLabel label="Change log" reqMark={true}>
            <TextArea
            required={true}
            style={{ height: "100px" ,width:"500px"}}
              className="my-form-input"
              value={update.change_log}
              onChange={(e) => setDetailsKey("change_log", e.target.value)}
            />
          </InputWithLabel>
          </div>
        {/* </div> */}
      </Form>
    </div>
  );
};

export default EditUpdate;
