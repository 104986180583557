import React from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Dropdown,
  Select,
  Cascader,
  Space,
  message,
  Tabs,
} from "antd";
import { useState, useEffect } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { InputWithLabel } from "../InputWithLabel";
import "../MobileUpdate/EditUpdate.css";
import TextArea from "antd/lib/input/TextArea";
import TabPane from "antd/lib/tabs/TabPane";
import { validateAppUpdate } from "../../Validation/Validations";
dayjs.extend(weekday);
dayjs.extend(localeData);
const dateFormat = "YYYY/MM/DD";
const validateMessages = {
  required: "${label} is required!",
};

export default function ({ isAddNew }) {
  const [details, setDetails] = useState([]);
  const [details2, setDetails2] = useState([]);
  const truefalseselect = [
    { key: true, value: "True" },
    { key: false, value: "False" },
  ];
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [errors, setErrors] = useState({});
  const m_id = useParams();
  console.log(m_id);

  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "details"
  );

  const [update, setUpdate] = useState({
    m_id: null,
    version_name: "",
    version_code: null,
    release_date: "",
    build_date: "",
    change_log: "",
    url: "",
    file_name: "",
    app_type: null,
    is_prod: null,
    created: "",
    updated: "",
    git_hash_num: "",
    app_id: null,
    is_enable: null,
    is_auto_logout : null,
    is_force_update:null,
    o_id: null,
    o_name: "",
  });

  const setDetailsKey = (key, value) => {
    setUpdate((d) => ({
      ...d,
      [key]: value,
    }));
  };

  function onSubmit(values) {
    console.log("add api called", values);
    let d, e, errorTab, errStatus, errorsIn;
    ({ d, e, errorTab, errStatus, errorsIn } = validateAppUpdate(
      update,
      errors,
      isAddNew,
      "details"
    ));
    setUpdate((prev) => ({ ...prev, ...d }));
    setErrors((prev) => ({ ...prev, ...e }));

    if (errStatus === "error") {
      console.log("Error in form : ", errorsIn);
      setActiveTab(errorTab);
      console.log(errors);
      setLoading(false);
      return;
    }
    if (isAddNew) {
        axios
        .post("/generic-api/mobile/create", {
          version_name: update.version_name,
          version_code: update.version_code,
          release_date: update.release_date,
          build_date: update.build_date,
          change_log: update.change_log,
          url: update.url,
          file_name: update.file_name,
          app_id: update.app_id,
          is_prod: update.is_prod || false,
          git_hash_num: update.git_hash_num,
          o_id: update.o_id,
          is_auto_logout : update.is_auto_logout || false,
          is_force_update : update.is_force_update || false,
          is_enable: update.is_enable || false,
        })
        .then((res) => {
          console.log("the data is added", res?.data);
          message.success("App Update Added Successfully");
          navigate("/home/update");
        })
        .catch((err) => {
          console.log(err);
          // message.error(err.response.data.detail[0].loc[1])
          message.error("Version code " + err.response.data.detail[0].msg);
        });
    console.log(values);
    }
    if (!isAddNew) {
        axios
      .put(`/generic-api/mobile/modify?m_id=${update.m_id}`, {
        ...update,
        // m_id: location.state.record.m_id,
      })
      .then((res) => {
        console.log(res.data);
        message.success("App Update Modified Successfully");
        navigate("/home/update");
      })
      .catch((err) => {
        console.log(err);
        message.error("Version code " + err.response.data.detail[0].msg);

      });
    }
      
  }

  const getDetails = async () => {
    await axios
      .get("/generic-api/get-mobile-by-mobile-id", {
        params: {
          m_id: m_id.m_id,
        },
      })
      .then((res) => {
        const data = res.data.data[0];
        console.log(data);
        setUpdate((d) => ({
          ...d,
          ...data,
          m_id: data.m_id,
          version_name: data.version_name || "",
          version_code: data.version_code,
          release_date: data.release_date,
          build_date: data.build_date,
          change_log: data.change_log,
          url: data.url,
          file_name: data.file_name,
          app_type: data.app_type,
          is_prod: data.is_prod,
          created: data.created,
          updated: data.updated,
          git_hash_num: data.git_hash_num,
          app_id: data.app_id,
          is_enable: data.is_enable,
          is_auto_logout : data.is_auto_logout,
          is_force_update : data.is_force_update,
          o_id: data.o_id,
          o_name: data.o_name,
        }));
        console.log(update);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get("generic-api/get_organisations")
      .then((res) => {
        setDetails2(res?.data?.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error : " + err);
      });

    await axios
      .get("generic-api/get_application_types")
      .then((res) => {
        setDetails(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error : " + err);
      });
  };

  console.log(update.version_name);
  useEffect(() => {
    if(state){
        setUpdate(state.record)
    }
    getDetails();
  }, []);

  // console.log(location?.state?.record)
  const saveButton = (
    <Space>
      <Button type="primary" danger onClick={() => navigate("/home/update")}>
        Cancel
      </Button>
      <Button
        type="primary"
        className="bg-sky-600"
        htmlType="submit"
        onClick={onSubmit}
      >
        Add
      </Button>
    </Space>
  );
  const changeActiveTab = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    setSearchParams(searchParams, { replace: true });
  };
  return (
    <div className="my-form-outer">
      <Tabs tabBarExtraContent={saveButton} activeKey={activeTab}>
        <TabPane className="my-form-tabpane-outer" tab="Details" key="details">
          <div className="my-form-multiple-inline-input">
            <InputWithLabel
              label="Organisation Name"
              reqMark={true}
              error={errors.o_id}
            >
              <Select
                status={errors?.o_id?.errors[0]?.msg && "error"}
                value={update.o_id}
                className="my-form-input"
                onChange={(value) => setDetailsKey("o_id", value)}
                options={details2?.map((items) => ({
                  value: items.o_id,
                  label: items.o_name,
                }))}
              />
            </InputWithLabel>

            <InputWithLabel
              label="Version name"
              reqMark={true}
              error={errors.version_name}
            >
              <Input
                status={errors?.version_name?.errors[0]?.msg && "error"}
                value={update.version_name}
                className="my-form-input"
                onChange={(e) => setDetailsKey("version_name", e.target.value)}
              />
            </InputWithLabel>

            <InputWithLabel
              label="Version code"
              reqMark={true}
              error={errors.version_code}
            >
              <Input
                status={errors?.version_code?.errors[0]?.msg && "error"}
                value={update.version_code}
                className="my-form-input"
                onChange={(e) => setDetailsKey("version_code", e.target.value)}
                placeholder="Enter Only Number"
              />
            </InputWithLabel>
          </div>

          <div className="my-form-multiple-inline-input">
            <InputWithLabel
              label="Release date"
              reqMark={true}
              error={errors.release_date}
            >
              <DatePicker
                status={errors?.release_date?.errors[0]?.msg && "error"}
                allowClear={false}
                value={
                  update.release_date !== ""
                    ? dayjs(update.release_date, dateFormat)
                    : null
                }
                onChange={(date, dateString) => {
                  setDetailsKey("release_date", date);
                }}
                className="my-form-input"
                format={dateFormat}
              />
            </InputWithLabel>
            <InputWithLabel
              label="Build date"
              reqMark={true}
              error={errors.build_date}
            >
              <DatePicker
                status={errors?.build_date?.errors[0]?.msg && "error"}
                allowClear={false}
                value={
                  update.build_date !== ""
                    ? dayjs(update.build_date, dateFormat)
                    : null
                }
                onChange={(date, dateString) => {
                  setDetailsKey("build_date", date);
                }}
                className="my-form-input"
                format={dateFormat}
              />
            </InputWithLabel>
            <InputWithLabel
              label="Git Hash Number"
              reqMark={true}
              error={errors.git_hash_num}
            >
              <Input
                status={errors?.git_hash_num?.errors[0]?.msg && "error"}
                value={update.git_hash_num}
                onChange={(e) => setDetailsKey("git_hash_num", e.target.value)}
                className="my-form-input"
              />
            </InputWithLabel>
          </div>

          <div className="my-form-multiple-inline-input">
            <InputWithLabel label="Url" reqMark={true} error={errors.url}>
              <Input
                status={errors?.url?.errors[0]?.msg && "error"}
                className="my-form-input"
                value={update.url}
                onChange={(e) => setDetailsKey("url", e.target.value)}
              />
            </InputWithLabel>
            <InputWithLabel
              label="File name"
              reqMark={true}
              error={errors.file_name}
            >
              <Input
                status={errors?.file_name?.errors[0]?.msg && "error"}
                className="my-form-input"
                value={update.file_name}
                onChange={(e) => setDetailsKey("file_name", e.target.value)}
              />
            </InputWithLabel>
            <InputWithLabel
              label="Application Name"
              reqMark={true}
              error={errors.app_id}
            >
              <Select
                status={errors?.app_id?.errors[0]?.msg && "error"}
                className="my-form-input"
                name="ApplicationName"
                placeholder="Select Application Name"
                value={update.app_id}
                onChange={(value) => setDetailsKey("app_id", value)}
                options={details?.map((item) => ({
                  value: item.application_id,
                  label: item.application_name,
                }))}
              ></Select>
            </InputWithLabel>
          </div>
          <div className="my-form-multiple-inline-input">
            <InputWithLabel
              label="Production"
            //   reqMark={true}
            //   error={errors.is_prod}
            >
              <Select
                // status={errors?.is_prod?.errors[0]?.msg && "error"}
                className="my-form-input"
                value={update.is_prod}
                onChange={(value) => setDetailsKey("is_prod", value)}
                options={truefalseselect?.map((item) => ({
                  value: item.key,
                  label: item.value,
                }))}
              ></Select>
            </InputWithLabel>
            <InputWithLabel
              label="Enable"
            //   reqMark={true}
            //   error={errors.is_enable}
            >
              <Select
                // status={errors?.is_enable?.errors[0]?.msg && "error"}
                className="my-form-input"
                name="Enable"
                value={update.is_enable}
                onChange={(value) => setDetailsKey("is_enable", value)}
                options={truefalseselect?.map((item) => ({
                  value: item.key,
                  label: item.value,
                }))}
              ></Select>
            </InputWithLabel>
            <InputWithLabel
              label="Logout"
              reqMark={true}
            //   error={errors.is_auto_logout}
            >
              <Select
                // status={errors?.is_auto_logout?.errors[0]?.msg && "error"}
                className="my-form-input"
                name="Logout"
                value={update.is_auto_logout}
                onChange={(value) => setDetailsKey("is_auto_logout", value)}
                options={truefalseselect?.map((item) => ({
                  value: item.key,
                  label: item.value,
                }))}
              ></Select>
            </InputWithLabel>
            <InputWithLabel
              label="Update"
              reqMark={true}
            //   error={errors.is_force_update}
            >
              <Select
                // status={errors?.is_force_update?.errors[0]?.msg && "error"}
                className="my-form-input"
                name="Update"
                value={update.is_force_update}
                onChange={(value) => setDetailsKey("is_force_update", value)}
                options={truefalseselect?.map((item) => ({
                  value: item.key,
                  label: item.value,
                }))}
              ></Select>
            </InputWithLabel>
          </div>
          <div className="my-form-multiple-inline-input">
            <InputWithLabel
              label="Change log"
              reqMark={true}
              error={errors.change_log}
            >
              <TextArea
                status={errors?.change_log?.errors[0]?.msg && "error"}
                required={true}
                style={{ height: "100px", width: "500px" }}
                className="my-form-input"
                value={update.change_log}
                onChange={(e) => setDetailsKey("change_log", e.target.value)}
              />
            </InputWithLabel>
          </div>
          {/* <Space className="mt-10">
                    <Button type="primary" danger onClick={() => navigate("/home/update")}>Cancel</Button>
                    <Button type="primary" className="bg-sky-600" htmlType="submit" >Add</Button>
                </Space> */}
        </TabPane>
      </Tabs>
    </div>
  );
}
