import React, { useState, useEffect } from "react";
import { Button, Divider, message, Popconfirm, Space, Spin, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import SearchComponent from "../SearchComponent";

export default function Organisations({ }) {
    const [users, setUser] = useState([]);
    const [ProcessedData, setProcessedData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);


    const [actionPlans, setActionPlans] = React.useState([]);
    const [masterState, setMasterState] = useState([]);
    const columns = React.useMemo(() => [
        {
            title: "Organisation Code",
            dataIndex: "o_code",
            key: "o_code",
        },
        {
            title: "Organisation Name",
            dataIndex: "o_name",
            key: "o_name",
        },
        {
            title: "license Used",
            dataIndex: "license",
            key: "license_limit",
        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_, record) =>
                ProcessedData.length >= 1 ? (
                    <>
                        <Space>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    navigate(
                                        `/home/organisations/edit/${record.o_id}`,

                                    );
                                }}
                                type="primary"
                                className="bg-sky-600"
                            >
                                Edit{" "}
                            </Button>
                            <Popconfirm
                                title="All the registers and licenses associated with this org will be deleted"
                                onConfirm={() => handleDelete(record)}
                            >
                                <Button type="primary" danger>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Space>
                    </>
                ) : null,
        },
    ]);

    function getOrgdata() {
        setLoading(true)
        axios
            .get("/generic-api/get_organisations")
            .then((res) => {
                setUser(res.data?.data);

                const data = res.data.data;
                const a = data.map((item) => ({
                    o_id: item.o_id,
                    o_code: item.o_code,
                    o_name: item.o_name,
                    license_limit: item.license_limit,
                    license_used: item.license_used,
                    license: item.license_used + "/" + item.license_limit,
                    o_urls: item.o_urls
                }))
                setProcessedData(a)
                setMasterState(a);
                setActionPlans(a);
                console.log(data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
    }
    const onSubmit = (e) => {
        e.preventDefault();
        navigate("/home/organisations/add");
        const o = {
            name: this.state.name,
            code: this.state.code,
        };
        axios
            .put(
                "/generic-api/get_organisations/{this.state.o_id}?{this.state.o_urls.application_id}",
                o
            )
            .then((res) =>

                console.log(res.data));
        console.log(this.state.name);
    };

    useEffect(() => {
        getOrgdata();
    }, []);
    const handleDelete = (record) => {
        console.log(record.o_id);
        axios
            .delete(`/generic-api/delete_organisations?o_id=${record.o_id} `)
            .then((res) => {
                if (res?.data?.data?.status_code == 200) {
                    message.success(record.o_name + " deleted successfully") &&
                        getOrgdata();
                } else {
                    message.error("OPPs something went wrong!");
                }
            })

            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
    };

    return (
        <>
            <header className="flex justify-between items-center" style={{padding:"5px"}}>
                <p className="my-form-title">Organisations</p>
                <Button
                    onClick={onSubmit}
                    type="primary"
                    style={{ margin: 1, float: "right", }}
                    className="bg-sky-600 font-bold">Add</Button>
            </header>
            <Divider />
            <div className="flex items-center">
                <SearchComponent
                    masterState={masterState}
                    state={actionPlans}
                    setState={setActionPlans}
                    searchOptions={[
                        { keyName: "o_name", label: "Organisation Name" },
                        { keyName: "o_code", label: "Organisation Code" },
                        { keyName: "license_limit", label: "License Limit" },
                    ]}
                />
            </div>
            <div className='my-form-outer mt-0'>
                {/* <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', height: "0px", fontSize: "small" }}>
                    <div></div>
                    <SearchComponent
                        masterState={masterState}
                        state={actionPlans}
                        setState={setActionPlans}
                        searchOptions={[
                            { keyName: "o_name", label: "Organisation Name" },
                            { keyName: "o_code", label: "Organisation Code" },
                            { keyName: "license_limit", label: "License Limit" },
                        ]}
                    />
                </div> */}
                <div style={{ position: 'relative', textAlign: 'right' }}>
                    {loading ? <Spin size="large" style={{ display: "flex", alignItems: "center", }} tip="Loading" /> :

                        <Table
                            columns={columns}
                            // loading={loading}
                            dataSource={actionPlans}
                            size="small"
                            pagination={{
                                position: ["bottomRight"],
                                // position: ['topRight'],
                                showSizeChanger: true,
                            }}
                        />
                    }

                </div>
            </div>
        </>
    );
}
