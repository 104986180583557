import { Button, message, Table, Tag } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { EditOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";


// data inserted in table
// "data": [
    // {
    //     "reg_id": 432,
    //     "register_code": "iam-gw-500120-137824b5fcd9",
    //     "hardware_details": "{\"system\": \"Linux\", \"node\": \"iam-gw-500120\", \"release\": \"5.10.17-v7+\", \"version\": \"#1414 SMP Fri Apr 30 13:18:35 BST 2021\", \"machine\": \"armv7l\", \"processor\": \"\"}",
    //     "last_communication": "2023-04-10T18:17:54.185588",
    //     "map_id": 416,
    //   },


export default function UnusedRegisters({
}) {
    const [masterState, setMasterState] = useState([]);
    const navigate = useNavigate();
    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    const [currentTableData, setCurrentTableData] = useState([]);


    const fixedColumns = [
       
        {
            title: 'Register Code',
            dataIndex: 'register_code',
            key: 'register_code',
        },
        {
            title: 'Hardware Details',
            dataIndex: 'hardware_details',
            key: 'hardware_details',
            render:(text,record)=> <><Button
            icon={<EyeOutlined />}
            onClick={() =>
                navigate(`/home/register_view/${record.reg_id}`, {
                    state: { record },
                })
            }
            className="bg-sky-600"
            type="primary"
        >
            View{" "}
        </Button></>
        },
        {
            title: 'Last Communication',
            dataIndex: 'last_communication',
            key: 'last_communication',
            render: (text, record) => moment(text).format("DD MMM YYYY hh:mm:ss a"),

        },
        // {
        //     title: 'Map Id',
        //     dataIndex: 'map_id',
        //     key: 'map_id',
        // },

    ];

    const getData = () => {
        setLoading(true);
        axios.get('/dashboard/total_unused_register', {
        })
            .then(res => {
                const data = res.data.data;
                console.log(data)
                setData(data);
                setMasterState(data);
                setCurrentTableData(data);       
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching Unused Registers Details');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    },[]);

    const columns = useMemo(() => [
        ...fixedColumns
    ].filter(c => c.hidden !== true));

    return (
        <div className='my-form-outer'>
            {/* <ProductCodeDetailsModal
                details={productCodeDetails}
                setDetails={setProductCodeDetails}
                categoryColumns={categoryColumns}
            /> */}
            <div className='my-form-header'>
                <span className='my-form-title'>Unused Registers</span>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                // loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
                onChange={(extra) => {
                    setCurrentTableData(extra.currentDataSource);
                }}
            />

        </div>
    )
}
