import React from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Dropdown,
  Select,
  Cascader,
  Space,
  Tabs,
  Radio,
  message,
  Alert,
  Spin,
} from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import "../License/EditLicense.css";
import axios from "axios";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
// import moment from "moment";
import { validateLicense } from "../../Validation/Validations";
import { InputWithLabel } from "../InputWithLabel";
import TabPane from "antd/lib/tabs/TabPane";
dayjs.extend(weekday);
dayjs.extend(localeData);
const dateFormat = "YYYY/MM/DD";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
};

export default function EditLicense({ isAddNew }) {
  const [ApplicationTypes, setApplicationTypes] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'details');
  const [loading, setLoading] = React.useState(false);
  const [OrganisationTypes, setOrganisationTypes] = useState([]);
  const license_id = useParams();
  const navigate = useNavigate();
 
  const [license, setLicense] = useState({
    license_name: "",
    license_token: "",
    license_key: "",
    license_validity: "",
    o_id: null,
    o_name: "",
    application_id: null,
    application_name: "",
    reg_id: null,
    register_code: "",
  });

  console.log(license_id);

  function onAddFinish(values) {
    let d, e, errorTab, errStatus, errorsIn;
    ({d, e, errorTab, errStatus, errorsIn} = validateLicense(license, errors, isAddNew, "details"));
    setLicense(prev => ({...prev, ...d}));  
    setErrors(prev => ({...prev, ...e}));

    if(errStatus === "error") {
        console.log("Error in form : ", errorsIn);
        setActiveTab(errorTab);
        setLoading(false);
        return;
    }
    console.log("add api called", values);
      axios
      .post("/generic-api/license-add", {
        ...license,
      })
      .then((res) => {
        console.log(res.data);
        message.success("License Added Successfully");
        navigate(`/home/edit_license/${res.data.license_id}`);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        message.error("License Add Failed : " + err.response.data.detail);
      });
    console.log(values);
    
  }

  function onEditFinish(values) {
    let d, e, errorTab, errStatus, errorsIn;
    ({d, e, errorTab, errStatus, errorsIn} = validateLicense(license, errors, isAddNew, "details"));
    setLicense(prev => ({...prev, ...d}));  
    setErrors(prev => ({...prev, ...e}));

    if(errStatus === "error") {
        console.log("Error in form : ", errorsIn);
        setActiveTab(errorTab);
        setLoading(false);
        return;
    }
    console.log("edit api called", values);
    axios
      .put("/generic-api/license-modify", {
        license_id: parseInt(license_id.license_id),
        ...license,
      })
      .then((res) => {
        if (res.data.data) {
          message.success(res.data.data);
        }
        console.log(res.data.data);

        navigate(`/home/edit_license/${license_id.license_id}`);
      })
      .catch((err) => {
        try {
          if (err.response.status === 400) {
            message.error("License Edit Failed : " + err.response.data.detail);
          }
        } catch (error) {
          message.error("Something went wrong! Refresh!");
        }
      });
    console.log(values);
  }

  const getDetails = async () => {
    await axios
      .get("generic-api/get_application_types")
      .then((res) => {
        setApplicationTypes(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get("generic-api/get_organisations")
      .then((res) => {
        const data = res.data.data;
        setOrganisationTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(
        `/generic-api/get-license-by-license-id?license_id=${license_id.license_id}`
      )
      .then((res) => {
        const data = res.data.data[0];
        console.log(data);
        setLicense((d) => ({
          ...d,
          ...data,

          license_name: data.license_name,
          license_token: data.license_token,
          license_key: data.license_key,
          license_validity: data.license_validity,
          o_id: data.o_id,
          o_name: data.o_name,
          application_id: data.application_id,
          application_name: data.application_name,
          reg_id: data.reg_id,
          register_code: data.register_code,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDetailsKey = (key, value) => {
    setLicense((d) => ({
      ...d,
      [key]: value,
    }));
  };

  useEffect(() => {
    getDetails();
  }, [license.license_key, license.license_token]);

  const saveButton = (
    <Space>
      <Button type="primary" danger onClick={() => navigate("/home/license")}>
        {" "}
        Cancel{" "}
      </Button>
      <Button type="primary" className="bg-sky-600" htmlType="submit" onClick={isAddNew == true ? onAddFinish : onEditFinish}>
        {isAddNew ? "Add" : "Save"}
      </Button>
    </Space>
  );
  const changeActiveTab = (key) => {
    setActiveTab(key);
    searchParams.set('tab', key);
    setSearchParams(searchParams, {replace: true});
}  
  return (
    <div className="my-form-outer">
        <Tabs activeKey={activeTab} tabBarExtraContent={saveButton} className="buttonlicense" onChange={changeActiveTab}>
        <TabPane className="my-form-tabpane-outer" tab="License Details" key="details">

        {/* <Space className="mt-3 float-right">
                    <Button type="primary" danger onClick={() => navigate("/home/license")}> Cancel </Button>
                    <Button type="primary" className="bg-sky-600" htmlType="submit" o>{isAddNew ? "Add" : "Save"}</Button>
                </Space> */}
        {/* <Spin spinning={loading}> */}
          <div className="my-form-multiple-inline-input">
            <InputWithLabel label="License Name" reqMark={true} error={errors.license_name}>
              <Input
              status={errors?.license_name?.errors[0]?.msg && 'error'}
                // required={true}
                value={license.license_name}
                className="my-form-input"
                onChange={(e) => setDetailsKey("license_name", e.target.value)}
              />
            </InputWithLabel>
          </div>

          <div className="my-form-multiple-inline-input">
            <InputWithLabel label="License Validity" reqMark={true} error={errors.license_validity}>
              <DatePicker
              status={errors?.license_validity?.errors[0]?.msg && 'error'}
                allowClear={false}
                value={
                  license.license_validity !== ""
                    ? dayjs(license.license_validity, dateFormat)
                    : null
                }
                onChange={(date, dateString) => {
                  setDetailsKey("license_validity", date);
                }}
                className="my-form-input"
                format={dateFormat}
              />

              {/* value={initialLicenseValidity} format="Do MMM, YYYY"  */}
            </InputWithLabel>
          </div>

          <div className="my-form-multiple-inline-input">
            {!isAddNew && (
              <>
                <InputWithLabel label="License Token" reqMark={true}>
                  <Alert
                    type="success"
                    message={license.license_token}
                    className="my-form-input"
                    style={{ height: "40px" }}
                  />
                  {/* <Radio.Button className="my-form-input">{details4}</Radio.Button> */}
                </InputWithLabel>
                <InputWithLabel label="License Key" reqMark={true}>
                  {/* <Radio.Button type="dashed" className="my-form-input">{details3}</Radio.Button> */}
                  <Space align="center">
                    <Alert
                      type="success"
                      message={license.license_key}
                      className="my-form-input"
                      style={{ height: "40px" }}
                    />
                    <Button
                      icon={<CopyOutlined disabled={isAddNew} />}
                      onClick={() => {
                        navigator.clipboard.writeText(license.license_key);
                        message.info(
                          `License Key Copied : ${license.license_key}`
                        );
                      }}
                    />
                  </Space>
                </InputWithLabel>
              </>
            )}
          </div>

          <div className="my-form-multiple-inline-input">
            <InputWithLabel label="Organisation Name" reqMark={true} error={errors.o_id}>
              <Select
              status={errors?.o_id?.errors[0]?.msg && 'error'}
                disabled={isAddNew === false ? true : false}
                // style={{ height: "35px", margin: "0px 0px" }}
                className="my-form-input"
                value={license.o_id}
                onChange={(value) => setDetailsKey("o_id", value)}
                options={OrganisationTypes?.map((items) => ({
                  value: items.o_id,
                  label: items.o_name,
                }))}
              />
            </InputWithLabel>
            <InputWithLabel label="Application Name" reqMark={true} error={errors.application_id}>
              <Select
              status={errors?.application_id?.errors[0]?.msg && 'error'}
                disabled={isAddNew === false ? true : false}
                // style={{ height: "35px", margin: "0px 0px" }}
                className="my-form-input"
                value={license.application_id}
                onChange={(value) => setDetailsKey("application_id", value)}
                options={ApplicationTypes?.map((item) => ({
                  value: item.application_id,
                  label: item.application_name,
                }))}
              ></Select>
            </InputWithLabel>
          </div>
        {/* </Spin> */}
        </TabPane>
        </Tabs>

    </div>
  );
}
