import { Button, Descriptions, Radio, message,Spin } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
const RegisterView = () => {
  const location = useLocation();
  const [register, setRegister] = useState([]);
  const [licenseValidity, setlicenseValidity] = useState([]);
  const [installDate, setinstallDate] = useState([]);
  const [installTIme, setinstallTIme] = useState([]);
  const [loading, setLoading] = useState(false);

  const [buildDate, setbuildDate] = useState([]);
  const [buildTIme, setbuildTIme] = useState([]);
  const [hardwaredetail, sethardwaredetail] = useState([]);
  const regID = useParams();
  const getDetails = async () => {
    
    setLoading(true);
    await axios
      .get(
        `/generic-api/get_register_by_register_id/?reg_id=${regID.reg_id}`
      )
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        // const bd = hardware.build_time.split(' ')
        // const it = hardware.app_install_time.split(' ')
        // setinstallDate(it[0])
        // setinstallTIme(it[1])
        // setbuildDate(bd[0])
        // setbuildTIme(bd[1])
        // const validity = data.license_validity.split("T")
        // setlicenseValidity(validity[0])
        // setHardware(data.hardware_details)
        setRegister(data);
        console.log("Register", register);
        setLoading(false);
        // const hardwaredata = data.hardware_details
        // sethardwaredetail(hardwaredata)
        // setHardware(hardwaredata)
        // console.log("Hradware Detail",hardware)
      })
      .catch((err) => {
        console.log(err);
        message.error("Error : " + err);
      });
  };
  useEffect(() => {
    getDetails();
  }, []);
  // let Validity = register.license_validity.split("T")
  const [size, setSize] = useState("default");
  const onChange = (e) => {
    console.log("size checked", e.target.value);
    setSize(e.target.value);
  };
  // let validity = register?.license_validity?.split("T");
  // let date = validity[0]
  return (
    <>
      {loading ? (
        <Spin
          size="large"
          style={{ display: "flex", alignItems: "center" }}
          tip="Loading"
        />
      ) : (
        <div>
          <Descriptions title="Register Details" size={size}>
            <Descriptions.Item
              label="Application Name"
              style={{ fontWeight: "bold" }}
            >
              {register.application_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Licence Name"
              style={{ fontWeight: "bold" }}
            >
              {register.license_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Licence Validity"
              style={{ fontWeight: "bold" }}
            >
              {register.license_validity}
            </Descriptions.Item>
            <Descriptions.Item
              label="Organisation Name"
              style={{ fontWeight: "bold" }}
            >
              {register.o_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Register Code"
              style={{ fontWeight: "bold" }}
            >
              {register.register_code}
            </Descriptions.Item>
            <Descriptions.Item
              label="Register Username"
              style={{ fontWeight: "bold" }}
            >
              {register.register_user_name}
            </Descriptions.Item>
            <Descriptions.Item label="Status" style={{ fontWeight: "bold" }}>
              {register.status_name}
            </Descriptions.Item>
          </Descriptions>
          {/* <Descriptions title = "Status" size='small'>
      <Descriptions.Item>{register.status_name}</Descriptions.Item>
      </Descriptions> */}
          <br />
          <br />
          <Descriptions title="Hardware Details" size={size}>
            {/* <Descriptions.Item label="Install Date" style={{fontWeight:"bold"}}>{register?.hardware_details?.installDate}</Descriptions.Item> */}
            <Descriptions.Item
              label="Install Time"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.app_install_time}
            </Descriptions.Item>
            <Descriptions.Item label="App Mode" style={{ fontWeight: "bold" }}>
              {register?.hardware_details?.app_mode}
            </Descriptions.Item>
            <Descriptions.Item
              label="Package Name"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.app_package_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="App Version"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.app_version}
            </Descriptions.Item>
            <Descriptions.Item
              label="Build Date"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.build_time}
            </Descriptions.Item>
            <Descriptions.Item
              label="Device Brand"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.device_brand}
            </Descriptions.Item>
            <Descriptions.Item
              label="Device Manufacturer"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.device_manufacturer}
            </Descriptions.Item>
            <Descriptions.Item
              label="Device Name"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.device_name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Device Type"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.device_type}
            </Descriptions.Item>
            <Descriptions.Item
              label="GPS Location"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.gps_location}
            </Descriptions.Item>
            <Descriptions.Item
              label="Model Name"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.model_name}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Model Version">{register?.hardware_details?.sdk_version}</Descriptions.Item> */}
            <Descriptions.Item
              label="OS Version"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.os_version}
            </Descriptions.Item>
            <Descriptions.Item
              label="SDK Version"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.sdk_version}
            </Descriptions.Item>
            <Descriptions.Item
              label="Version Code"
              style={{ fontWeight: "bold" }}
            >
              {register?.hardware_details?.version_code}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </>
  );
};
export default RegisterView;
