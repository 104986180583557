import { Validator as V } from "./Validator";

// The details state is `d` (For small names)

export const validateLicense = (d, e, isAddNew, apTab="details") => {
    V.errorTab = "";
    V.errorStatus = "success";
    V.errorsIn = [];

    // Name
    ({ value: d.license_name, error: e.license_name } = new V(d.license_name, "License Name", apTab).strip().req().v());

    // Audit Remark
    ({ value: d.license_validity, error: e.license_validity} = new V(d.license_validity, "License Validity", apTab).strip().req().v());
    ({ value: d.o_id, error: e.o_id} = new V(d.o_id, "Organisation Name", apTab).strip().req().v());
    ({ value: d.application_id, error: e.application_id} = new V(d.application_id, "Application Name", apTab).strip().req().v());

    // strip description and comments
    // ({ value: d.o_id } = new V(d.o_id, "Organisation Name", apTab).strip());
    // ({ value: d.application } = new V(d.comments, "Comments", apTab).strip());
    
    const errStatus = V.errorStatus;
    const errorsIn = V.errorsIn;
    const errorTab = V.errorTab;

    return {d, e, errorTab, errStatus, errorsIn};
}
export const validateAppUpdate = (d, e, isAddNew, zgTab="details") => {
    V.errorTab = "";
    V.errorStatus = "success";
    V.errorsIn = [];

    // Name
    ({ value: d.o_id, error: e.o_id } = new V(d.o_id, "Organisation Name", zgTab).strip().req().v());
    ({ value: d.version_name, error: e.version_name } = new V(d.version_name, "Version Name", zgTab).strip().req().v());
    ({ value: d.version_code, error: e.version_code } = new V(d.version_code, "Version Code", zgTab).strip().req().v());
    ({ value: d.release_date, error: e.release_date } = new V(d.release_date, "Release Date", zgTab).strip().req().v());
    ({ value: d.build_date, error: e.build_date } = new V(d.build_date, "Build Date", zgTab).strip().req().v());
    ({ value: d.git_hash_num, error: e.git_hash_num } = new V(d.git_hash_num, "Git Hash Number", zgTab).strip().req().v());
    ({ value: d.url, error: e.url } = new V(d.url, "URL", zgTab).strip().req().v());
    ({ value: d.file_name, error: e.file_name } = new V(d.file_name, "File Name", zgTab).strip().req().v());
    ({ value: d.application_id, error: e.app_id } = new V(d.app_id, "Application Name", zgTab).strip().req().v());
    // ({ value: d.is_prod, error: e.is_prod } = new V(d.is_prod, "Production Status", zgTab).strip().req().v());
    // ({ value: d.is_enable, error: e.is_enable } = new V(d.is_enable, "Enabled Status", zgTab).strip().req().v());
    ({ value: d.change_log, error: e.change_log } = new V(d.change_log, "Change Log", zgTab).strip().req().v());


    const errStatus = V.errorStatus
    const errorsIn = V.errorsIn;
    const errorTab = V.errorTab;

    return {d, e, errorTab, errStatus, errorsIn};
}
export const validateApplication = (d, e, isAddNew, zgTab="details") => {
    V.errorTab = "";
    V.errorStatus = "success";
    V.errorsIn = [];

    // Name
    ({ value: d.application_name, error: e.application_name } = new V(d.application_name, "Application Name", zgTab).strip().req().v());
    ({ value: d.application_code, error: e.application_code } = new V(d.application_code, "Application Code", zgTab).strip().req().v());

    const errStatus = V.errorStatus
    const errorsIn = V.errorsIn;
    const errorTab = V.errorTab;

    return {d, e, errorTab, errStatus, errorsIn};
}
export const validateOrganisation = (d, e, isAddNew, zgTab="org") => {
    V.errorTab = "";
    V.errorStatus = "success";
    V.errorsIn = [];

    // Name
    ({ value: d.o_code, error: e.o_code } = new V(d.o_code, "Organisation Code", zgTab).strip().req().v());
    ({ value: d.o_name, error: e.o_name } = new V(d.o_name, "Organisation Name", zgTab).strip().req().v());
    ({ value: d.license_limit, error: e.license_limit } = new V(d.license_limit, "License Limit", zgTab).strip().req().v());

    const errStatus = V.errorStatus
    const errorsIn = V.errorsIn;
    const errorTab = V.errorTab;

    return {d, e, errorTab, errStatus, errorsIn};
}
export const validateUser = (d, e, isAddNew, zgTab="details") => {
    V.errorTab = "";
    V.errorStatus = "success";
    V.errorsIn = [];

    // Name
    ({ value: d.username, error: e.username } = new V(d.username, "Username", zgTab).strip().req().v());
    ({ value: d.password, error: e.password } = new V(d.password, "Password", zgTab).strip().req().v());

    const errStatus = V.errorStatus
    const errorsIn = V.errorsIn;
    const errorTab = V.errorTab;

    return {d, e, errorTab, errStatus, errorsIn};
}

