import React, { useState, useMemo } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import {
    DesktopOutlined,
    PaperClipOutlined,
    PicCenterOutlined,
    ClusterOutlined,
    BuildOutlined,
    AppstoreOutlined,
    ContactsOutlined,
    IdcardOutlined,
    HomeOutlined,
    UserOutlined,
    LogoutOutlined,
    SearchOutlined,
    DashboardOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, message, theme } from "antd";

import GlobalSearch from "react-global-search";
import MenuItem from "antd/lib/menu/MenuItem";
const { Header, Content, Footer, Sider } = Layout;

function logoutHandler() {
    window.location.href = "/";
    localStorage.removeItem("logged");
}

const Navbar = () => {
    const navigate = useNavigate();
    const [admin, setAdmin] = useState(true)
    const items = useMemo(() => [
        // {
        //   key: "Organisations",
        //   label: "Organisations",
        //   description: "Organisation",
        //   icon: <DesktopOutlined />,
        //   search: "Organisations",
        //   onClick: () => {
        //     navigate("/home/organisations");
        //   },
        // },
        // {
        //   key: "License",
        //   label: "License",
        //   description: "License",
        //   icon: <DesktopOutlined />,
        //   search: "License",
        //   onClick: () => {
        //     navigate("/home/license");
        //   },
        // },
        // {
        //   key: "Registers",
        //   label: "Registers",
        //   description: "Registers",
        //   icon: <DesktopOutlined />,
        //   search: "Registers",
        //   onClick: () => {
        //     navigate("/home/registers");

        //   },
        // },
        // {
        //   key: "Application",
        //   label: "Application",
        //   description: "Application",
        //   icon: <DesktopOutlined />,
        //   search: "Application",
        //   onClick: () => {
        //     navigate("/home/application");
        //   },
        // },
        // {
        //   key: "App Update",
        //   label: "App Update",
        //   description: "App Update",
        //   icon: <DesktopOutlined />,
        //   search: "App Update",
        //   onClick: () => {
        //     navigate("/home/update");
        //   },
        // },
    ]);
    let x = localStorage.getItem('AdminValidate')
    console.log(x)
    const [collapsed, setCollapsed] = useState(false);
    const [selectKey, setSelectKey] = useState(window.sessionStorage.getItem("key"))

    // const {
    //   token: { colorBgContainer },
    // } = theme.useToken();
    return (
        <Layout
            style={{
                minHeight: "100vh",
            }}
        >
            <Sider
            style={{position:"fixed", overflow:"auto", minHeight:"100vh"}}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}>
                {/* Side bar header */}
                <div className="p-2 flex space-x-2 my-auto">
                    <img width='40px' style={{marginLeft:"10px"}} className="object-fill" src="https://images.smart-iam.com/logo.png" />
                    <p className="text-white text-lg">{!collapsed?" Smart iAM":""}</p>
                </div>

                <Menu defaultActiveFirst={window.sessionStorage.setItem("key",['1'])} theme="dark" mode="inline" defaultSelectedKeys={ selectKey }>
                <Menu.Item key="1" onClick={() => {
                        navigate("/home");
                        setSelectKey(['1'])
                        window.sessionStorage.setItem("key",['1'])
                    }}>
                        <div>
                        <DashboardOutlined/>
                        <span>Dashboard</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => {
                        navigate("/home/organisations");
                        setSelectKey(['2'])
                        window.sessionStorage.setItem("key",['2'])
                    }}>
                        <ClusterOutlined />
                        <span>Organizations</span>
                    </Menu.Item>
                    <Menu.Item key="3" onClick={() => {
                        navigate("/home/license");
                        setSelectKey(['3'])
                        window.sessionStorage.setItem("key",['3'])
                    }}>
                        <IdcardOutlined />
                        <span>License</span>
                    </Menu.Item>
                    <Menu.Item key="4" onClick={() => {
                        navigate("/home/registers");
                        setSelectKey(['4'])
                        window.sessionStorage.setItem("key",['4'])
                    }}>
                        <PicCenterOutlined />
                        <span>Registers</span>
                    </Menu.Item>
                    <Menu.Item key="5" onClick={() => {
                        navigate("/home/application");
                        setSelectKey(['5'])
                        window.sessionStorage.setItem("key",['5'])
                    }}>
                        <AppstoreOutlined />
                        <span>Application</span>
                    </Menu.Item>
                    <Menu.Item key="6" onClick={() => {
                        navigate("/home/update");
                        setSelectKey(['6'])
                        window.sessionStorage.setItem("key",['6'])
                    }}>
                        <BuildOutlined />
                        <span>App Update</span>
                    </Menu.Item>
                    {x === 'true' && (
                        <Menu.Item key="7" onClick={() => {
                            navigate("/home/users");
                            setSelectKey(['7'])
                            window.sessionStorage.setItem("key",['7'])
                        }}>
                            <UserOutlined />
                            <span>Users</span>
                        </Menu.Item>
                    )}
                    <Menu.Item key="8" danger onClick={() => {
                        if (localStorage.getItem('AdminToken') === 'true') {
                            window.location.href = "/";
                            localStorage.removeItem("AdminValidate");
                        }
                        else {
                            message.error("Cannot Logout without an admin")
                        }
                    }}>
                        <LogoutOutlined />
                        <span>Logout</span>
                    </Menu.Item>
                </Menu>
            </Sider>

            <Layout className="site-layout">
                {/* <Header style={{paddingLeft: 10,background: "#001628eb" }}>{header}</Header> */}
                <Content  style={{overflow:"hidden" , padding: 10, marginLeft:!collapsed?"190px": "90px" ,marginTop:"0px", position:"relative"}}>
                    {/* <Breadcrumb style={{ margin: "0px 0" }}>
                    </Breadcrumb> */}
                    <div
                    
                        style={{
                            padding: 14,
                            minHeight: 360,
                            background: "#FFFFFF",
                        }}
                    >
                        <Outlet />
                    </div>
                </Content>

                <Footer
                    style={{
                        textAlign: "center",
                    }}
                ></Footer>
            </Layout>
        </Layout>
    );
};
export default Navbar;