import React, { useEffect, useState } from "react";
import { Table, Button, Space, Popconfirm, message, Spin, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SearchComponent from "../SearchComponent";

const Application = () => {

    const [info, setInfo] = useState([]);
    const [actionPlans, setActionPlans] = React.useState([]);
    const [masterState, setMasterState] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    function request() {
        setLoading(true);
        axios
            .get("generic-api/get_application_types")
            .then((res) => {
                setInfo(res.data);
                const data = res.data;
                console.log(data);
                setActionPlans(data);
                setMasterState(data);
                setLoading(false);
                // console.log(users)
            })
            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
    }

    useEffect(() => {
        request();
    }, []);

    const handleDelete = (record) => {
        console.log(record.application_id);
        axios
            .delete(
                `/generic-api/delete_application_types?application_id=${record.application_id} `
            )
            .then((res) => {
                if (res?.data?.data?.status_code == 200) {
                    message.success(record.application_name + " deleted successfully") &&
                        request();
                } else {
                    message.error("OPPs something went wrong!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const Columns = [
        {
            title: "Application Name",
            dataIndex: "application_name",
            key: "application_name",
        },
        {
            title: "Application Code",
            dataIndex: "application_code",
            key: "application_code",
        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_, record) =>
                info.length >= 1 ? (
                    <>
                        <Space>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => { navigate(`/home/application/edit/${record.application_id}`, {}); }}
                                className="bg-sky-600"
                                type="primary">Edit</Button>

                            <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => handleDelete(record)}>
                                <Button type="primary" danger>Delete</Button>
                            </Popconfirm>
                        </Space>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            <header className="flex justify-between items-center" style={{padding:"5px"}}>
                <p className="my-form-title">Application</p>
                <Button
                    type="primary"
                    style={{ margin: 0, float: "right" }}
                    onClick={() => navigate("/home/application/add")}
                    className="bg-sky-600">Add</Button>
            </header>
            <Divider />
            <div className="flex justify-between items-center h-16">
                <SearchComponent
                    masterState={masterState}
                    state={actionPlans}
                    setState={setActionPlans}
                    searchOptions={[
                        { keyName: "application_name", label: "Application Name" },
                        { keyName: "application_code", label: "Application Code" },
                    ]}
                />
            </div>
            <div className="my-form-outer">
                {/* <div
                    style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "0px",
                        fontSize: "small",
                    }}
                >
                    <div></div>
                    <SearchComponent
                        masterState={masterState}
                        state={actionPlans}
                        setState={setActionPlans}
                        searchOptions={[
                            { keyName: "application_name", label: "Application Name" },
                            { keyName: "application_code", label: "Application Code" },
                        ]}
                    />
                </div> */}

                {loading ? (
                    <Spin
                        size="large"
                        style={{ display: "flex", alignItems: "center" }}
                        tip="Loading"
                    />
                ) : (
                    <Table
                        bordered
                        dataSource={actionPlans}
                        columns={Columns}
                        loading={loading}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                        }}
                    />
                )}
            </div>
        </>
    );
};
export default Application;
