import React, { useEffect, useState } from "react";
import { Table, Button, Popconfirm, Space, message, Spin, Tooltip, Tag, Menu, Select, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SearchComponent from "../SearchComponent";


const Application = () => {

    const [orgList, setOrgList] = useState([]);
    const [info, setInfo] = useState([]);
    const [regID, setRegID] = useState([]);
    const [actionPlans, setActionPlans] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [masterState, setMasterState] = useState([]);
    const navigate = useNavigate();

    const onSelect = (val) => {
        getRegisterByOrgId(val)
    };

    function getRegisterByOrgId(org_id) {
        setLoading(true);
        axios
            .get("/generic-api/get-license-id-by-org-id?o_id=" + org_id)
            .then((res) => {
                const data = res.data.data;
                setInfo(res.data.data);
                setRegID(res)
                setMasterState(data);
                setActionPlans(data);
                setLoading(false);
            })
            .catch((err) => {
                message.error("Error : " + err);
                console.log(err);
            });

    }

    const [details, setDetails] = useState({
        application_code: "",
        application_id: 0,
        application_name: "",
        created_time: "",
        is_deleted: 0,
    });

    const [selected, setSelected] = useState({
        dateRange: [moment(), moment()],
    });

    function getAllOrgList() {
        setLoading(true);
        axios
            .get("/generic-api/get_organisations")
            .then((res) => {
                const dataList = []
                const data = res.data.data;
                for (let i = 0; i < data.length; i++) {
                    let org_json = {
                        "value": data[i].o_id,
                        "label": data[i].o_name,
                    }
                    dataList.push(org_json)
                }
                setOrgList(dataList)
                setLoading(false);
            })
            .catch((err) => {
                message.error("Error : " + err);
                console.log(err);
            });

    }

    function request() {
        setLoading(true);
        axios
            .get("/generic-api/get-all-license")
            .then((res) => {
                const data = res.data.data;
                setInfo(res.data.data);
                setRegID(res)
                setMasterState(data);
                setActionPlans(data);
                setLoading(false);
            })
            .catch((err) => {
                // message.error(err.message);
                message.error("Error : " + err);
                console.log(err);
            });
        // axios
        // .get(`/generic-api/get_register_by_register_id/?${license_id?.license_id}`)
        // .then((res) => {
        //   const data = res.data.data;
        //   setInfo(res.data.data);
        //   setMasterState(data);
        //   setActionPlans(data);
        //   console.log(data);
        //   // setLoading(false);
        // })
        // .catch((err) => {
        //   // message.error(err.message);
        //   message.error("Error : " + err);
        //   console.log(err);
        // });

    }

    const handleDelete = (record) => {
        console.log(record);
        console.log("Delete Called");
        axios
            .delete(`/generic-api/license-delete?license_id=${record.license_id} `)
            .then((res) => {
                message.success("License Deleted Successfully")
                request();
            })

            .catch((err) => {
                console.log(err);
                message.error("License Delete Failed : " + err.response.data.detail)
            });
    };

    useEffect(() => {
        request();
        getAllOrgList();
    }, []);

    const Columns = [
        {
            title: "License Name",
            dataIndex: "license_name",
            key: "license_name",
            render: (text) => <b>{text}</b>,
        },
        {
            title: "Register Name",
            dataIndex: "register_user_name",
            key: "register_user_name",
            render: (text, record) => (
                <a onClick={() => navigate(`/home/register_view/${record.reg_id}`, {})}>{text} </a>
            ),
        },
        {
            title: "Oragnisation Name",
            dataIndex: "o_name",
            key: "o_name",
        },
        {
            title: "Application Name",
            dataIndex: "application_name",
            key: "application_name",
        },
        {
            title: "License Token",
            dataIndex: "license_token",
            key: "license_token",
        },
        {
            title: "License Key",
            dataIndex: "license_key",
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="dashed"
                            onClick={() => {
                                if (navigator.clipboard && window.isSecureContext) {
                                    navigator.clipboard.writeText(record.license_key);
                                    message.info(`License Key Copied : ${record.license_key}`);
                                } else {
                                    let textArea = document.createElement("textarea");
                                    textArea.value = record.license_key;
                                    textArea.style.position = "fixed";
                                    textArea.style.left = "-999999px";
                                    textArea.style.top = "-999999px";
                                    document.body.appendChild(textArea);
                                    textArea.focus();
                                    textArea.select();
                                    message.info(`License Key Copied : ${record.license_key}`);
                                    return new Promise((res, rej) => {
                                        document.execCommand("copy") ? res() : rej();
                                        textArea.remove();
                                    });
                                }
                            }}
                        >
                            View & Copy
                        </Button>
                    </>
                );
            },
        },
        {
            title: "License Validity",
            dataIndex: "license_validity",
            render: (text, record) => {
                let date = moment(record.license_validity);
                let now = moment();
                let diff = date.diff(now, "days");
                if (diff > -1) {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "green" }}>
                                    {diff} days left to expire
                                </span>
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "red" }}>Expired</span>
                            </Tooltip>
                        </>
                    );
                }
            },
            sorter: (a, b) => moment(a.license_validity).diff(moment(b.license_validity)),

        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_, record) =>
                info.length >= 1 ? (
                    <>
                        <Space>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => navigate(`/home/edit_license/${record.license_id}`,)}
                                type="primary"
                                className="bg-sky-600">Edit{" "}</Button>
                            <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => handleDelete(record)}>
                                <Button type="primary" danger>Delete</Button>
                            </Popconfirm>
                        </Space>
                    </>
                ) : null,
        },
    ];

    return (

        <>
            <header className="flex justify-between items-center" style={{padding:"5px"}}>
                <p className="my-form-title">License</p>
                <Button
                    onClick={() => navigate("/home/add_license")}
                    type="primary"
                    style={{ margin: 0, float: "right", }}
                    className="bg-sky-600" >Add</Button>
            </header>
            <Divider />
            <div className="flex justify-between items-center">
                <Select
                    defaultValue="Select orgnisation"
                    onChange={onSelect}
                    style={{ width: 220, marginLeft:"10px" }}
                    options={orgList}
                />
                <SearchComponent
                    masterState={masterState}
                    state={actionPlans}
                    setState={setActionPlans}
                    searchOptions={[
                        { keyName: "license_name", label: "License Name" },
                        { keyName: "license_token", label: "License Token" },
                        { keyName: "o_name", label: "Organisation Name" },
                        { keyName: "application_name", label: "Application Name" },
                    ]}
                />
            </div>
            <div className="my-form-outer">
                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        defaultValue="Select orgnization"
                        onChange={onSelect}
                        style={{ width: 220 }}
                        options={orgList}
                    />
                    <SearchComponent
                        masterState={masterState}
                        state={actionPlans}
                        setState={setActionPlans}
                        searchOptions={[
                            { keyName: "license_name", label: "License Name" },
                            { keyName: "license_token", label: "License Token" },
                            { keyName: "o_name", label: "Organisation Name" },
                            { keyName: "application_name", label: "Application Name" },
                        ]}
                    />
                </div> */}

                <div style={{ position: "relative", textAlign: "right" }}>
                    {loading ? (
                        <Spin
                            size="large"
                            style={{ display: "flex", alignItems: "center" }}
                            tip="Loading"
                        />
                    ) : (
                        <Table
                            columns={Columns}
                            loading={loading}
                            dataSource={actionPlans}
                            size="small"
                            pagination={{ position: ["bottomRight"], showSizeChanger: true, }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
export default Application;
