import React, { useState, useEffect, useRef } from "react";
import { Button, Popconfirm, Space, Table, Input, Spin, message, Tooltip, Select, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { EditOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import SearchComponent from "../SearchComponent";
import moment from "moment";
import Highlighter from "react-highlight-words";

export default function Registers({ }) {
    const [actionPlans, setActionPlans] = React.useState([]);
    const [masterState, setMasterState] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [loading, setLoading] = React.useState(false);
    const searchInput = useRef(null);
    const [users, setUser] = useState([]);
    const navigate = useNavigate();
    const columns = [
        {
            title: "Organisation Name",
            dataIndex: "o_name",
            key: "o_name",
        },
        {
            title: "License Name",
            dataIndex: "license_name",
            render: (text, record) => (
                <a onClick={() => navigate(`/home/edit_license/${record.license_id}`, {})}>{text}</a>
            ),
        },
        {
            title: "Application Name",
            dataIndex: "application_name",
            key: "application_name",
        },
        {
            title: "Register Name",
            dataIndex: "register_user_name",
            key: "register_user_name",
        },
        {
            title: "Register Code",
            dataIndex: "register_code",
            key: "register_code",
        },
        {
            title: "Status",
            dataIndex: "status_name",
        },
        {
            title: "License Validity",
            dataIndex: "license_validity",
            render: (text, record) => {
                let date = moment(record.license_validity);
                let now = moment();
                let diff = date.diff(now, "days");
                if (diff > -1) {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "green" }}>
                                    {diff} days left to expire
                                </span>
                            </Tooltip>
                        </>
                    );
                }
                // if licnese validaty is null the envalid date will be shown
                else if (record.license_validity === null) {
                    return (
                        <>
                            <span>Invalid Date</span>
                        </>
                    );

                }
                else {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "red" }}>Expired</span>
                            </Tooltip>
                        </>
                    );
                }
            },
            sorter: (a, b) => moment(a.license_validity).diff(moment(b.license_validity)),
        },

        {
            title: "Operation",
            dataIndex: "operation",
            render: (_, record) =>
                users.length >= 1 ? (
                    <>
                        <Space>
                            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                                <Button type="primary" danger>Delete</Button>
                            </Popconfirm>
                            <Button
                                icon={<EyeOutlined/>}
                                onClick={() =>
                                    navigate(`/home/register_view/${record.reg_id}`, {
                                        state: { record },
                                    })
                                }
                                className="bg-sky-600"
                                type="primary"
                            >
                                View{" "}
                            </Button>

                            {record.license_name === null ? (
                                <></>
                            ) : (
                                <Popconfirm
                                    title="Sure to delete?"
                                    onConfirm={() => Derigister(record)}
                                >
                                    <Button
                                        type="primary"
                                        style={{ background: "orange", borderColor: "orange" }}
                                    >
                                        Detach
                                    </Button>
                                </Popconfirm>
                            )}
                        </Space>
                    </>
                ) : null,
        },
    ];

    const [orgList, setOrgList] = useState([]);
    const onSelect = (val) => {
        if (val === 0) {
            getRegisterByOrgId(val, true)
        } else {
            getRegisterByOrgId(val, false)
        }
    };

    function getAllOrgList() {
        setLoading(true);
        axios
            .get("/generic-api/get_organisations")
            .then((res) => {
                const dataList = [{ "label": "Un-Register", "value": 0 }]
                const data = res.data.data;
                for (let i = 0; i < data.length; i++) {
                    let org_json = {
                        "value": data[i].o_id,
                        "label": data[i].o_name,
                    }
                    dataList.push(org_json)
                }
                setOrgList(dataList)
                setLoading(false);
            })
            .catch((err) => {
                message.error("Error : " + err);
                console.log(err);
            });

    }

    function getRegisterByOrgId(org_id, flag) {
        setLoading(true);
        axios
            .get("/generic-api/get-register-by-o-id?o_id=" + org_id + "&open_flag=" + flag)
            .then((res) => {
                const data = res.data.data;
                setMasterState(data);
                setActionPlans(data);
                setLoading(false);
            })
            .catch((err) => {
                message.error("Error : " + err);
                console.log(err);
            });

    }

    function getRegdata() {
        setLoading(true);
        axios
            .get("/generic-api/get-all-register")
            .then((res) => {
                setUser(res.data?.data);
                const data = res.data.data;
                setActionPlans(data);
                setMasterState(data);
                console.log(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
    }
    const onSubmit = (e) => {
        e.preventDefault();
        navigate("/home/register_add");
        const o = {
            name: this.state.name,
            code: this.state.code,
        };
        axios
            .put("/generic-api/get-all-register/{this.state.o_id}", o)
            .then((res) => console.log(res.data));
        console.log(this.state.name);
    };

    useEffect(() => {
        getRegdata();
        getAllOrgList();
    }, []);

    const Derigister = (record) => {
        console.log(record.register_code);
        axios
            .put(`/generic-api/detach_device/?register_code=${record.register_code} `)
            .then((res) => {
                message.success("Register Detached Successfully");
                getRegdata();
            })

            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
    };

    const handleDelete = (record) => {
        console.log(record.register_code);
        axios
            .delete(
                `/generic-api/delete-register-code?register_code=${record.register_code} `
            )
            .then((res) => {
                message.success("Register Deleted Successfully");
                getRegdata();
            })

            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
    };

    return (
        <>
            <header className="flex justify-between items-center" style={{padding:"5px"}}>
                <p className="my-form-title">Registers</p>
            </header>
            <Divider />
            <div className="flex justify-between items-center h-16">
                <Select
                    defaultValue="Select orgnisation"
                    onChange={onSelect}
                    style={{
                        width: 220, marginLeft:"10px"
                    }}
                    options={orgList}
                />

                <SearchComponent
                    masterState={masterState}
                    state={actionPlans}
                    setState={setActionPlans}
                    searchOptions={[
                        { keyName: "o_name", label: "Organisation Name" },
                        { keyName: "license_name", label: "License Name" },
                        { keyName: "application_name", label: "Application Name" },
                        { keyName: "register_user_name", label: "Register Name" },
                        { keyName: "register_code", label: "Register Code" },
                        // {keyName: 'zone_groups', label: 'Zone Groups'},
                    ]}
                />
            </div>
            <div>
                {/* <span className="my-form-title">Registers</span> */}
                {/* <div
                style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "0px",
                    fontSize: "small",
                }}
            > */}
                {/* <div style={{ display: 'flex', alignItems: 'center' }}>

                    <Select
                        defaultValue="Select orgnization"
                        onChange={onSelect}
                        style={{
                            width: 220,
                        }}
                        options={orgList}
                    />

                    <SearchComponent
                        masterState={masterState}
                        state={actionPlans}
                        setState={setActionPlans}
                        searchOptions={[
                            { keyName: "o_name", label: "Organisation Name" },
                            { keyName: "license_name", label: "License Name" },
                            { keyName: "application_name", label: "Application Name" },
                            { keyName: "register_user_name", label: "Register Name" },
                            { keyName: "register_code", label: "Register Code" },
                            // {keyName: 'zone_groups', label: 'Zone Groups'},
                        ]}
                    />

                </div> */}

                {/* </div> */}
                {loading ? (
                    <Spin
                        size="large"
                        style={{ display: "flex", alignItems: "center" }}
                        tip="Loading"
                    />
                ) : (
                    <Table
                        loading={loading}
                        rowClassName={() => "editable-row"}
                        bordered
                        dataSource={actionPlans}
                        columns={columns}
                        pagination={{
                            position: ["bottomRight"],
                            // position: ['topRight'],
                            showSizeChanger: true,
                        }}
                    />
                )}
            </div>
        </>
    );
}
