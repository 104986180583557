import { useEffect, useState } from "react";
import "../CSS/Login.css";
import Card from "antd/es/card/Card";
import { Button, Form, Input, Typography, message } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
const { Title } = Typography;

// import "./styles.css";
const users = [
  {
    username: "admin",
    password: "admin",
  },
  {
    username: "admin2",
    password: "012345678",
  },
];
export default function Login() {
  const location = useLocation();
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [auth, setauth] = useState(false);
  // const authReducer = useSelector(state => state.authReducer);
  const [login, setlogin] = useState(false);
  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const checkUser = () => {
    const usercheck = users.find(
      (user) =>
        user.username === data.username && user.password === data.password
    );
    if (usercheck) {
      setlogin(true);
    } else {
      setlogin(false);
    }
    // console.log(uname);
    console.log(usercheck);
  };
  const onFinish = async(values) => {   
    console.log(values)     
    await axios.post(`/generic-api/login?username=${values.username}&password=${values.password}`, {
  username: values.username,
  password: values.password
})
  .then(res => {
    console.log(res.data.data.is_admin);
    localStorage.setItem("AdminValidate", res?.data?.data.is_admin);
    window.location.href = "/home";
    // localStorage.setItem("RoleToken", res.data.data.role_token);
            // navigate();
            // window.location.href = location?.state?.from || "/";
  })
  .catch(err => {
    console.log(err);
    message.error("Login failed - " + err.message);
    // localStorage.removeItem("AdminToken")
  })

};

  useEffect(() => {
    checkUser(users);
  }, [data.username, data.password]);
  function submitHandler() {
    if (login) {
      window.location.href = "/home";
      console.log("Login successful");
      // localStorage.setItem("logged", true);
    } else {
      window.location.href = "/";
      console.log("Wrong password or username");
      localStorage.removeItem("logged");
    }
  }

  console.log(data);
  return (
    <>
      <div className="login">
        <div className="appAside">
          {/* <img src="loginpage.jpg" height="50%" width="50%"></img> */}
        </div>

        {/* <div className="appForm"> */}
        <div
          style={{
            height: "100vh",
            backgroundImage: `url("/loginpage.jpg")`,
            backgroundColor: "white",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              minWidth: "100%",
              minHeight: "100%",
              textAlign: "center",
              display: "-webkit-flex",
              alignItems: "center",
            }}
          >
            <Card
              bordered={true}
              hoverable={true}
              style={{
                margin: "Auto",
                width: "400px",
              }}
            >
              <br></br>
              <Title style={{ color: "black" }}>Generic UI Login</Title>
              <Form
              onFinish={onFinish}
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  style={{ color: "black" }}
                  label={
                    <p
                      style={{
                        fontSize: "20px",
                        color: "black",
                        marginTop: "18px",
                      }}
                    >
                      Username
                    </p>
                  }
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    name="username"
                    size="large"
                    autoFocus
                    value={data.username}
                    onChange={changeHandler}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <p
                      style={{
                        color: "black",
                        fontSize: "20px",
                        marginTop: "18px",
                      }}
                    >
                      Password
                    </p>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    size="large"
                    value={data.password}
                    onChange={changeHandler}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    // onClick={submitHandler}
                    style={{ backgroundColor: "#ea5150", color: "black" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
