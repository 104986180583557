import React from "react";
import {
    Button,
    Form,
    Input,
    InputNumber,
    DatePicker,
    Space,
    Tabs,
    Select,
    Row,
    Col,
    message,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo } from "react";
import {
    useNavigate,
    createSearchParams,
    useSearchParams,
    useParams,
    useLocation,
} from "react-router-dom";
import axios from "axios";
import TabPane from "antd/es/tabs/TabPane";
import { InputWithLabel } from "../InputWithLabel";
import { validateApplication } from "../../Validation/Validations";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const { RangePicker } = DatePicker;
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

/* eslint-enable no-template-curly-in-string */

const onFinish = (values) => {
    console.log("Received values of form:", values);
};

export default function EditApp({ isAddNew }) {
    const a_id = useParams();
    console.log(a_id)
    const location = useLocation();
    const [users, setUser] = useState([]);
    const [application, setApplication] = useState({
        application_id: null,
        application_name: "",
        is_deleted: null,
        application_code: "",
        created_time: ""
    });
    const [aid, setAid] = useState([]);
    const [details2, setDetails2] = useState([]);
    const [orgid, setorgid] = useState(" ");
    const [orgname, setorgname] = useState(" ");
    const [orgurl, setorgurl] = useState(" ");
    const [orgcode, setorgcode] = useState(" ");
    const [licenselimit, setLicenseLimit] = useState(100);
    const [applicationID, setApplicationID] = useState(1);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [errors, setErrors] = useState({});
    const [applicationtype, setApplicationtype] = useState([]);
    const [activeTab, setActiveTab] = useState(
        searchParams.get("tab") || "details"
      );
      const changeActiveTab = (key) => {
        setActiveTab(key);
        searchParams.set("tab", key);
        setSearchParams(searchParams, { replace: true });
      };
    console.log(location?.state?.record?.application_id)
    function onSubmit(values) {
        console.log("add api called", values);
        let d, e, errorTab, errStatus, errorsIn;
        ({ d, e, errorTab, errStatus, errorsIn } = validateApplication(
          application,
          errors,
          isAddNew,
          "details"
        ));
        setApplication((prev) => ({ ...prev, ...d }));
        setErrors((prev) => ({ ...prev, ...e }));
    
        if (errStatus === "error") {
          console.log("Error in form : ", errorsIn);
          setActiveTab(errorTab);
          console.log(errors);
          setLoading(false);
          return;
        }
        if (isAddNew) {
            axios
            .post("/generic-api/add_application_types", {
                ...application
            })
            .then((res) => {
                message.success("Application added successfully");
                navigate("/home/application")
            })
            .catch((err) => {
                message.error(err);
                message.error("Error : " + err);
            });
        console.log(values);
        {
            console.log(isAddNew);
        }
        
        }
        if (!isAddNew) {
            axios
            .put("/generic-api/modify_application_types", {
                ...application
            })
            .then((res) => {
                message.success("Application Edited successfully");
                navigate("/home/application")
            })
            .catch((err) => {
                console.log(err);
                message.error("Error : " + err);
            });
        console.log(values);
        {
            console.log(isAddNew);
        }
        }
          
      }
    const setDetailsKey = (key, value) => {
        setApplication((d) => ({
            ...d,
            [key]: value,
        }));
    };

    const getDetails = async () => {
        axios
            .get("generic-api/get_application_types")
            .then((res) => {
                setApplicationtype(res.data);
                // console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
        axios
            .get(`/generic-api/get_app_by_app_id/?a_id=${a_id.application_id}`)
            .then((res) => {
                const data = res?.data?.data[0]
                console.log(res.data.data[0])
                setApplication((d) => ({
                    ...d,
                    ...data,
                    application_id: data.application_id,
                    application_name: data.application_name,
                    is_deleted: data.is_deleted,
                    application_code: data.application_code,
                    created_time: data.created_time

                }));

                // console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
    };

    useEffect(() => {
        getDetails();
    }, []);

    const saveButton = (
        <Space>
            <Button type="primary" danger onClick={() => navigate("/home/application")}>Cancel</Button>
            <Button type="primary" className="bg-sky-600" htmlType="submit" onClick={onSubmit}>{isAddNew ? "Add" : "Save"}</Button>
        </Space>
    );

    return (
        <>
        <div className="my-form-outer">
                <Tabs className="card-tabs" tabBarExtraContent={saveButton} activeKey={activeTab} onChange={changeActiveTab}>
                <TabPane className="my-form-tabpane-outer" tab="Application Details" key="details">

                {/* <Space className="mt-10 float-right">
                    <Button type="primary" danger onClick={() => navigate("/home/application")}>Cancel</Button>
                    <Button type="primary" className="bg-sky-600" htmlType="submit" onClick={onSubmit}>{isAddNew ? "Add" : "Save"}</Button>
                </Space> */}
                <div className="my-form-outer">
                    <div className="my-form-multiple-inline-input">
                        <InputWithLabel label="Application Name" reqMark={true} error={errors.application_name}>
                            <Input value={application.application_name}
                            status={errors?.application_name?.errors[0]?.msg && "error"}
                                className="my-form-input"
                                onChange={(e) => setDetailsKey("application_name", e.target.value)} />
                        </InputWithLabel>
                        <InputWithLabel label="Application Code" reqMark={true} error={errors.application_code}>
                            <Input value={application.application_code}
                            status={errors?.application_code?.errors[0]?.msg && "error"}
                                className="my-form-input"
                                onChange={(e) => setDetailsKey("application_code", e.target.value)}
                                disabled={isAddNew === false ? true : false} />
                        </InputWithLabel>
                    </div>
                </div>
                </TabPane>
                </Tabs>
                </div>
        </>
    );
}
