import { Button, message, Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';


// data inserted in table
// {
//   "data": [
//     {
    //   "license_id": 157,
    //   "license_name": "Testing ",
    //   "license_token": "6180MATAGIDSU0033",
    //   "license_key": "3MATAGIDSU0038002",
    //   "license_validity": "2023-04-28T10:17:36",
    //   "o_name": "Amol PC",
    //   "application_name": "Mobile App Tag Id",
//     },


export default function TotalUnused({
}) {
    const [masterState, setMasterState] = useState([]);
    
    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    const [currentTableData, setCurrentTableData] = useState([]);


    const fixedColumns = [

        {
            title: 'License Name',
            dataIndex: 'license_name',
            key: 'license_name',
            render: (text, record) => <><Link to={`/home/edit_license/${record.license_id}`}><b>{text}</b></Link></>
        },
        {
            title: 'License Token',
            dataIndex: 'license_token',
            key: 'license_token',
        },
        {
            title: 'License Key',
            dataIndex: 'license_key',
            key: 'license_key',
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="dashed"
                            onClick={() => {
                                if (navigator.clipboard && window.isSecureContext) {
                                    navigator.clipboard.writeText(record.license_key);
                                    message.info(`License Key Copied : ${record.license_key}`);
                                } else {
                                    let textArea = document.createElement("textarea");
                                    textArea.value = record.license_key;
                                    textArea.style.position = "fixed";
                                    textArea.style.left = "-999999px";
                                    textArea.style.top = "-999999px";
                                    document.body.appendChild(textArea);
                                    textArea.focus();
                                    textArea.select();
                                    message.info(`License Key Copied : ${record.license_key}`);
                                    return new Promise((res, rej) => {
                                        document.execCommand("copy") ? res() : rej();
                                        textArea.remove();
                                    });
                                }
                            }}
                        >
                            View & Copy
                        </Button>
                    </>
                );
            },
        },
        {
            title: 'License Validity',
            dataIndex: 'license_validity',
            key: 'license_validity',
            render: (text, record) => {
                let date = moment(record.license_validity);
                let now = moment();
                let diff = date.diff(now, "days");
                if (diff > -1) {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "green" }}>
                                    {diff} days left to expire
                                </span>
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "red" }}>Expired</span>
                            </Tooltip>
                        </>
                    );
                }
            },
            sorter: (a, b) => moment(a.license_validity).diff(moment(b.license_validity)),

        },
        {
            title: 'Org Name',
            dataIndex: 'o_name',
            key: 'o_name',
        },
        {
            title: 'Application Name',
            dataIndex: 'application_name',
            key: 'application_name',
        },

    ];

    const getData = () => {
        setLoading(true);
        axios.get('/dashboard/total_unused_licenses', {
        })
            .then(res => {
                const data = res.data.data;

                setData(data);
                setMasterState(data);
                setCurrentTableData(data);       
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching Total Unused');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    },[]);

    const columns = useMemo(() => [
        ...fixedColumns
    ].filter(c => c.hidden !== true));

    return (
        <div className='my-form-outer'>
            {/* <ProductCodeDetailsModal
                details={productCodeDetails}
                setDetails={setProductCodeDetails}
                categoryColumns={categoryColumns}
            /> */}
            <div className='my-form-header'>
            <span className='my-form-title'>Total Unused Licenses</span>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                // loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
                onChange={(extra) => {
                    setCurrentTableData(extra.currentDataSource);
                }}
            />

        </div>
    )
}
