import { message, Table, Button, Tooltip, Drawer } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

// data inserted in table
// {
//   "data": [
//     {
//       "license_id": 142,
//       "license_name": "SUNIL",
//       "license_token": "1131MATAGIDIAM0006117",
//       "license_key": "117MATAGIDIAM00062528",
//       "license_validity": "2024-03-31T06:45:11",
//       "o_id": 117,
//       "reg_id": 407
//     },


export default function TotalActive({
}) {
    const [masterState, setMasterState] = useState([]);
    
    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [currentTableData, setCurrentTableData] = useState([]);
    const [open, setOpen] = useState(false);
    // const { token } = theme.useToken();
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const containerStyle = {
      position: 'relative',
      height: 200,
      padding: 48,
      overflow: 'hidden',
      textAlign: 'center',
    //   background: token.colorFillAlter,
    //   border: `1px solid ${token.colorBorderSecondary}`,
    //   borderRadius: token.borderRadiusLG,
    };

    const fixedColumns = [
        {
            title: "License Name",
            dataIndex: "license_name",
            key: "license_name",
            render: (text, record) => <><Link to={`/home/edit_license/${record.license_id}`}><b>{text}</b></Link>
            {/* <div style={containerStyle}> */}
      {/* </div> */}
            </>,
        },
        // {
        //     title: "Register Name",
        //     dataIndex: "register_user_name",
        //     key: "register_user_name",
        //     render: (text, record) => (
        //         <a onClick={() => navigate(`/home/register_view/${record.reg_id}`, {})}>{text} </a>
        //     ),
        // },
        {
            title: "Oragnisation Name",
            dataIndex: "o_name",
            key: "o_name",
        },
        // {
        //     title: "Application Name",
        //     dataIndex: "application_name",
        //     key: "application_name",
        // },
        {
            title: "License Token",
            dataIndex: "license_token",
            key: "license_token",
        },
        {
            title: "License Key",
            dataIndex: "license_key",
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="dashed"
                            onClick={() => {
                                if (navigator.clipboard && window.isSecureContext) {
                                    navigator.clipboard.writeText(record.license_key);
                                    message.info(`License Key Copied : ${record.license_key}`);
                                } else {
                                    let textArea = document.createElement("textarea");
                                    textArea.value = record.license_key;
                                    textArea.style.position = "fixed";
                                    textArea.style.left = "-999999px";
                                    textArea.style.top = "-999999px";
                                    document.body.appendChild(textArea);
                                    textArea.focus();
                                    textArea.select();
                                    message.info(`License Key Copied : ${record.license_key}`);
                                    return new Promise((res, rej) => {
                                        document.execCommand("copy") ? res() : rej();
                                        textArea.remove();
                                    });
                                }
                            }}
                        >
                            View & Copy
                        </Button>
                    </>
                );
            },
        },
        {
            title: "License Validity",
            dataIndex: "license_validity",
            render: (text, record) => {
                let date = moment(record.license_validity);
                let now = moment();
                let diff = date.diff(now, "days");
                if (diff > -1) {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "green" }}>
                                    {diff} days left to expire
                                </span>
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "red" }}>Expired</span>
                            </Tooltip>
                        </>
                    );
                }
            },
            sorter: (a, b) => moment(a.license_validity).diff(moment(b.license_validity)),

        },
    ];

    const getData = () => {
        setLoading(true);
        axios.get('/dashboard/total_active_last_seven_days', {
        })
            .then(res => {
                const data = res.data.data;
                console.log(data)
                setData(data);
                setMasterState(data);
                setCurrentTableData(data);       
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching Total Active Details');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    },[]);

    const columns = useMemo(() => [
        ...fixedColumns
    ].filter(c => c.hidden !== true));

    return (
        <div className='my-form-outer'>
            {/* <ProductCodeDetailsModal
                details={productCodeDetails}
                setDetails={setProductCodeDetails}
                categoryColumns={categoryColumns}
            /> */}
            <div className='my-form-header'>
            <span className='my-form-title'> Active Last 7 Days</span>

            </div>
            <Table
                dataSource={data}
                columns={columns}
                // loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
                onChange={(extra) => {
                    setCurrentTableData(extra.currentDataSource);
                }}
            />

        </div>
    )
}
