import React from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Space,
  Tabs,
  Select,
  Row,
  Col,
  message,
  Divider,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { InputWithLabel } from "../InputWithLabel";
import {
  validateOrg,
  validateOrganisation,
} from "../../Validation/Validations";
const { TabPane } = Tabs;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
export default function EditOrg({ isAddNew }) {
  const [org, setOrg] = useState({
    license_limit: null,
    license_used: null,
    o_name: "",
    o_code: "",
    o_id: null,
    o_urls: [],
  });
  const o_id = useParams();
  console.log(o_id);
  const [loading, setLoading] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "org"
  );
  const changeActiveTab = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    setSearchParams(searchParams, { replace: true });
  };
  // const [EditDetails, setEditDetails] = useState({
  //   "o_id": 0,
  //     "o_name": "",
  //     "o_code": "",
  //     "license_limit": 0,
  //     "o_urls": []
  // })
  const [applicationtype, setApplicationtype] = useState([]);
  function onSubmit(values) {
    console.log("api called", values);
    let d, e, errorTab, errStatus, errorsIn;
    ({ d, e, errorTab, errStatus, errorsIn } = validateOrganisation(
      org,
      errors,
      isAddNew,
      "org"
    ));
    setOrg((prev) => ({ ...prev, ...d }));
    setErrors((prev) => ({ ...prev, ...e }));

    if (errStatus === "error") {
      console.log("Error in form : ", errorsIn);
      setActiveTab(errorTab);
      console.log(errors);
      setLoading(false);
      return;
    }
    if (isAddNew) {
      axios
        .post("/generic-api/add_organisations", {
          o_name: org.o_name,
          o_code: org.o_code,
          license_limit: org.license_limit,
          o_urls: values.o_urls || [],
        })
        .then((res) => {
          message.success("Organisation added successfully");
          navigate("/home/organisations");
        })
        .catch((err) => {
          console.log(err);
          message.error("Error : " + err);
        });
      console.log(values);
      {
        console.log(isAddNew);
      }
    }
    if (!isAddNew) {
      axios
        .put("/generic-api/modify_organisations", {
          o_id: o_id.o_id,
          o_name: org.o_name,
          o_code: org.o_code,
          license_limit: org.license_limit,
          o_urls: values.o_urls || [],
        })
        .then((res) => {
          console.log("data updated");
          message.success("Organisation Modified successfully");
          navigate("/home/organisations");
        })
        .catch((err) => {
          console.log(err);
          try {
            if (err.response.status === 400) {
              message.error(err.response.data.detail);
            }
          } catch (error) {
            message.error("Something went wrong! Refresh!");
          }
        });
      console.log(values);
      {
        console.log(isAddNew);
      }
    }
  }

  const navigate = useNavigate();
  const getDetails = async () => {
    axios
      .get("generic-api/get_application_types")
      .then((res) => {
        setApplicationtype(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error : " + err);
      });
    axios
      .get(`/generic-api/get_org_by_id/?o_id=${o_id?.o_id}`)
      .then((res) => {
        const data = res?.data?.data;
        console.log(res.data.data);
        setOrg((d) => ({
          ...d,
          ...data,
          license_limit: data.license_limit,
          license_used: data.license_used,
          o_name: data.o_name,
          o_code: data.o_code,
          o_id: data.o_id,
          o_urls: data.o_urls,
        }));
      })
      .catch((err) => {
        console.log(err);
        // message.error("Error : " + err);
      });
  };
  console.log(org);
  const setDetailsKey = (key, value) => {
    setOrg((d) => ({
      ...d,
      [key]: value,
    }));
  };
  useEffect(() => {
    getDetails();
  }, []);

  const saveButton = (
    <Space>
      <Button
        type="primary"
        danger
        onClick={() => navigate("/home/organisations")}
      >
        Cancel
      </Button>

      <Button type="primary" className="bg-sky-600" htmlType="submit">
        {isAddNew ? "Add" : "Save"}
      </Button>
    </Space>
  );
  const tabItemClick = (key) => {
    if (key === "Add Org") {
    } else {
    }
    console.log("tab click", key);
  };
  return (
    <>
      <Form
        name="item_form"
        // initialValues={org}
        onFinish={onSubmit}
      >
        <Tabs
          className="card-tabs"
          tabBarExtraContent={saveButton}
          onChange={changeActiveTab}
          activeKey={activeTab}
        >
          <TabPane tab="Add org" key="org">
            <div className="my-form-outer">
              <div className="my-form-multiple-inline-input">
                <InputWithLabel
                  label="Organisation Code"
                  reqMark={true}
                  error={errors.o_code}
                >
                  <Input
                    status={errors?.o_code?.errors[0]?.msg && "error"}
                    value={org.o_code}
                    className="my-form-input"
                    onChange={(e) => setDetailsKey("o_code", e.target.value)}
                  />
                </InputWithLabel>
                <InputWithLabel
                  label="Organisation Name"
                  reqMark={true}
                  error={errors.o_name}
                >
                  <Input
                    status={errors?.o_name?.errors[0]?.msg && "error"}
                    value={org.o_name}
                    className="my-form-input"
                    onChange={(e) => setDetailsKey("o_name", e.target.value)}
                  />
                </InputWithLabel>
                <InputWithLabel
                  label="License Limit"
                  reqMark={true}
                  error={errors.license_limit}
                >
                  <InputNumber
                    status={errors?.license_limit?.errors[0]?.msg && "error"}
                    value={org.license_limit}
                    className="my-form-input"
                    onChange={(e) => setDetailsKey("license_limit", e)}
                    min={org.license_used}
                  />
                </InputWithLabel>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Url" key="url">
            <Form.List name="o_urls" initialValue={org.o_urls}>
              {(fields, { add, remove }) => (
                <>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      className="bg-sky-600"
                      // icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </Form.Item>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <div className="my-form-multiple-inline-input">
                        <InputWithLabel label="Application name" reqMark={true}>
                          <Form.Item
                            {...restField}
                            name={[name, "application_id"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Application name",
                              },
                            ]}
                          >
                            <Select
                              className="my-form-input"
                              showSearch
                              placeholder="Application name"
                              optionFilterProp="children"
                              // onChange={(value) => setUrlsKey("application_id", value)}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={applicationtype.map((item) => ({
                                value: item.application_id,
                                label: item.application_name,
                              }))}
                            />
                          </Form.Item>
                        </InputWithLabel>
                        <InputWithLabel label="Application Url" reqMark={true}>
                          <Form.Item
                            name={[name, "url"]}
                            // onChange={(e) => setUrlsKey("url", e.target.value)}
                            rules={[
                              {
                                required: true,
                                message: "Missing Application URL"
                              },
                            ]}
                          >
                            <Input className="my-form-input" />
                          </Form.Item>
                        </InputWithLabel>

                        <InputWithLabel>
                          <Form.Item>
                            <Button
                              // className="my-form-input"
                              style={{ margin: "21px 0px" }}
                              danger
                              onClick={() => remove(name)}
                              type="primary"
                              // icon={<MinusCircleOutlined />}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </InputWithLabel>
                      </div>
                      <Divider />
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </TabPane>
        </Tabs>
      </Form>
    </>
  );
}
