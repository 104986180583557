import { Button, message, Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';

// data inserted in table
// {
//   "data": [
//     
    //   "license_id": 174,
    //   "license_name": "",
    //   "license_token": "2570MATAGIDSU0033",
    //   "license_validity": "2023-04-22T09:54:43",
    //   "o_name": "Amol PC"
//     },


export default function ExpiryInSeven({
}) {
    const [masterState, setMasterState] = useState([]);
    
    // Below satate will hold the data filtered after `Our Search Component (SearchComponent)`
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    const [currentTableData, setCurrentTableData] = useState([]);


    const fixedColumns = [
        {
            title: 'License Name',
            dataIndex: 'license_name',
            key: 'license_name',
            render: (text, record) => <><Link to={`/home/edit_license/${record.license_id}`}><b>{text}</b></Link></>
        },
        {
            title: 'License Token',
            dataIndex: 'license_token',
            key: 'license_token',
        },
        {
            title: 'License Validity',
            dataIndex: 'license_validity',
            key: 'license_validity',
            render: (text, record) => {
                let date = moment(record.license_validity);
                let now = moment();
                let diff = date.diff(now, "days");
                
                    return (
                        <>
                            <Tooltip title={moment(record.license_validity).format("DD-MM-YYYY HH:mm:ss")}>
                                <span style={{ color: "green" }}>
                                    {diff} days left to expire
                                </span>
                            </Tooltip>
                        </>
                    );
                
            }
        },
        {
            title: 'Org Name',
            dataIndex: 'o_name',
            key: 'o_name',
        },
    ];

    const getData = () => {
        setLoading(true);
        axios.get('/dashboard/expiry_in_seven_days', {
        })
            .then(res => {
                const data = res.data.data;

                setData(data);
                setMasterState(data);
                setCurrentTableData(data);       
            })
            .catch(err => {
                // setFilterOptions({});
                setData([]);
                setCurrentTableData([]);

                console.log(err);
                message.error('Error fetching Details of Expiry in Seven Day');
            })
        setLoading(false);
    }

    useEffect(() => {
        getData();
    },[]);

    const columns = useMemo(() => [
        ...fixedColumns
    ].filter(c => c.hidden !== true));

    return (
        <div className='my-form-outer'>
            <div className='my-form-header'>
            <span className='my-form-title'> Expiry in 7 Days</span>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                // loading={loading}
                size="small"
                pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
                onChange={(extra) => {
                    setCurrentTableData(extra.currentDataSource);
                }}
            />

        </div>
    )
}
