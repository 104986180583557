import React, { useEffect, useState } from "react";
import { Table, Button, Space, Popconfirm, message, Spin, Tag, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SearchComponent from "../SearchComponent";

const Users = () => {
    const [info, setInfo] = useState([]);
    const [actionPlans, setActionPlans] = React.useState([]);
    const [masterState, setMasterState] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [length, setLength] = useState([])
    const [Admin, setAdmin] = useState([])
    function request() {
        setLoading(true);
        axios
            .get("/generic-api/get_users")
            .then((res) => {
                // setInfo(res.data);
                const data = res.data;
                const filteredData = res.data.filter(entry => entry.is_admin === true);
                console.log(filteredData)
                const a = filteredData.map((item, entry) => ({
                    admin: item.is_admin
                }))
                setLength(res.data)
                setAdmin(a)
                console.log(a);
                setActionPlans(data);
                setMasterState(data);
                setLoading(false);
                // console.log(users)
            })
            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
    }
    function checkAdmin() {
        if (Admin.length < 1) {
            localStorage.removeItem('AdminToken')
        } if (Admin.length >= 1) {
            localStorage.setItem('AdminToken', true)
        }
    }
    useEffect(() => {
        request();
        checkAdmin();
    }, [Admin.length]);
    const handleDelete = (record) => {
        console.log(record.application_id);
        axios
            .delete(
                `/generic-api/delete_user?user_id=${record.user_id} `
            )
            .then((res) => {
                if (res?.data?.data?.status_code == 200) {
                    message.success("User "+  record.username + " deleted successfully") &&
                        request();
                } else {
                    message.error("OPPs something went wrong!");
                }
            })

            .catch((err) => {
                console.log(err);
                // message.error("Error : " + err);
            });
    };
    const Columns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Created Time",
            dataIndex: "created_time",
            render: (text, record) => moment(text).format("DD MMM YYYY hh:mm:ss a"),
            width: '30%'
        },
        {
            title: "Admin",
            dataIndex: "is_admin",
            key: "is_admin",
            render: (text, record) => (record.is_admin === false ? <div><Tag color="error" style={{ 'font-size': 'medium', 'padding': '5px' }}>False</Tag></div> : <div><Tag color="success" style={{ 'font-size': 'medium', 'padding': '5px' }}>True</Tag></div>)
        },
        {
            title: "Operation",
            dataIndex: "operation",
            width: '10%',
            render: (_, record) =>
                info.length >= 0 ? (
                    <>
                        <Space>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    navigate(`/home/users/edit/${record.user_id}`, {

                                        // state: { record },

                                    });
                                }}
                                type="primary"
                                className="bg-sky-600"
                            >
                                Edit{" "}
                            </Button>
                            <Popconfirm
                                title={Admin.length < 1 ? "Add admin first, delete anyways?" : "Sure to delete?"}
                                onConfirm={() => handleDelete(record)}

                                disabled={Admin.length <= 1 && length.length == 1}
                            >
                                {Admin.length < 1 && (
                                    <Button danger type="primary" style={{ 'pointer-events': 'none' }}>Add an admin first</Button>
                                )}
                                {Admin.length >= 1 && (
                                    <Button type="primary" danger disabled={length.length == 1 && Admin.length <= 1}>
                                        Delete
                                    </Button>
                                )}

                            </Popconfirm>
                        </Space>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            <header className="flex justify-between items-center" style={{padding:"5px"}}>
                <p className="my-form-title">Users</p>
                <Button
                    type="primary"
                    style={{
                        margin: 0,
                        float: "right",
                    }}
                    className="bg-sky-600"
                    onClick={() => navigate("/home/users/add")}
                >
                    Add
                </Button>
            </header>
            <Divider />
            <div className="flex justify-between items-center h-16">
                <SearchComponent
                    masterState={masterState}
                    state={actionPlans}
                    setState={setActionPlans}
                    searchOptions={[
                        { keyName: "username", label: "Username" },
                        // { keyName: "password", label: "Password" },
                        // {keyName: 'zone_groups', label: 'Zone Groups'},
                    ]}
                />
            </div>
            <div className="my-form-outer">
                {/* <div
                    style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "0px",
                        fontSize: "small",
                    }}
                >
                    <div></div>
                    <SearchComponent
                        masterState={masterState}
                        state={actionPlans}
                        setState={setActionPlans}
                        searchOptions={[
                            { keyName: "username", label: "Username" },
                            // { keyName: "password", label: "Password" },
                            // {keyName: 'zone_groups', label: 'Zone Groups'},
                        ]}
                    />
                </div> */}

                {loading ? (
                    <Spin
                        size="large"
                        style={{ display: "flex", alignItems: "center" }}
                        tip="Loading"
                    />
                ) : (
                    <Table
                        bordered
                        dataSource={actionPlans}
                        columns={Columns}
                        loading={loading}
                        pagination={{
                            position: ["bottomRight"],
                            // position: ['topRight'],
                            showSizeChanger: true,
                        }}
                    />
                )}
            </div>
        </>
    );
};
export default Users;